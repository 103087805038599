.text-expand {
  position: relative;
  margin: 20px 0 50px;
  background-color: $scolor2;
  border-radius: 5px;

  a {
    color: $primary2;
    text-decoration: underline;
  }

  &__content {
    font-family: Roboto;
    font-size: 14px;
    position: relative;
    color: $gray2;
    padding: 15px 15px 0 15px;
    height: 138px;
    overflow: hidden;

    h1,
    h2,
    h3 {
      font-weight: 500;
      line-height: 19px;
    }

    h1 {
      color: $white;
      font-size: 18px;
    }

    h2,
    h3 {
      color: $body-color;
      font-size: 16px;
    }

    h4,
    h5 {
      font-size: 14px;
    }

    blockquote {
      border-left: 5px solid #f1f1f1;
      padding-left: 5px;

      &::before {
        content: "\201C";
      }

      &::after {
        content: "\201D";
      }
    }
  }

  &__button-wrapper {
    background-color: $scolor2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
  }

  &__button {
    background: $scolor4;
    border: none;
    border-radius: 5px;
    color: $white;
    font-size: 14px;
    margin-left: 15px;
    height: 35px;
    padding: 0 15px;
    &:hover {
      background: $scolor3;
    }
  }

  &.expanded {
    .text-expand__content {
      height: auto;
      overflow: auto;
    }
    .text-expand__button {
      margin-left: 0;
    }
    .text-expand__button-wrapper {
      position: static;
    }
  }
}
