.history {
  display: flex;
  margin: 12px 0 0 0;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }

  &__tabs {
    padding: 0 8px 0 0;
    width: 25%;
    max-width: 200px;
    @media screen and (max-width: 950px) {
      width: 100%;
      max-width: none;
      padding: 0;
    }

    .radio-tabs {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 950px) {
        flex-direction: row;
      }

      > li {
        width: 100%;
        margin: 0 0 8px !important;

        @media screen and (max-width: 950px) {
          margin: 0 8px 0 0 !important;
        }

        &:last-child {
          margin: 0 !important;
        }
      }
    }
  }

  &__container {
    width: 75%;
    flex-grow: 1;
    background: #0c161f;
    padding: 20px;
    border-radius: 6px;

    @media screen and (max-width: 950px) {
      width: 100%;
    }
    @media screen and (max-width: 950px) {
      padding: 4px;
    }

    &-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 900px) {
        flex-wrap: wrap;
      }

      > div:first-child {
        margin: 0 8px 0 0;
        max-width: 150px;

        @media screen and (max-width: 900px) {
          max-width: none;
          width: 100%;
          margin: 0 0 8px 0;
        }
      }

      > div:last-child {
        max-width: 250px;

        @media screen and (max-width: 900px) {
          max-width: none;
          width: 100%;
        }
      }

      .input-search .input-group-text {
        height: 48px;
      }

      .input-search .form-control {
        height: 48px;
      }
    }

    .date-header {
      background: none;
      padding: 16px 0 4px 0;
      font-size: 16px;
    }
  }

  &__game {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 8px 0 0 0;

    &-wrapper {
      border-radius: 6px 6px 0 0;
      background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
      width: 100%;
    }

    &-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 6px 6px 0 0;
      min-height: 56px;
      align-items: center;
      user-select: none;
      cursor: pointer;

      &-row1 {
        display: flex;
        width: 100%;
        padding: 12px 0 4px;
        align-items: stretch;

        @media screen and (max-width: 700px) {
          flex-wrap: wrap;
        }

        &-col1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 100px;
          padding: 0 4px;

          @media screen and (max-width: 700px) {
            flex-direction: row;
            width: auto;
            flex-grow: 1;
            padding: 0;

            .history__game-header-date {
              margin-left: auto;
              padding: 0 12px;
            }
          }
        }

        &-col2 {
          display: flex;
          flex-direction: column;
          width: 300px;
          padding: 0 4px;

          @media screen and (max-width: 700px) {
            width: 100%;
            padding: 0 12px;
          }
        }

        &-col3 {
          margin: 0 0 0 auto;
          padding: 0 12px;

          @media screen and (max-width: 700px) {
            width: 100%;

            .history__game-header-rate {
              text-align: right;
            }
          }
        }
      }

      &-row2 {
        display: flex;
        width: 100%;
        padding: 12px 0;
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width: 700px) {
          flex-wrap: wrap;
        }

        &-col1 {
          display: flex;
          justify-content: flex-end;
          width: 300px;
          margin: 0 0 0 auto;
          padding: 0 12px;

          @media screen and (max-width: 700px) {
            width: 100%;
          }
        }

        &-col2 {
          display: flex;
          justify-content: flex-end;
          padding: 0 12px;

          @media screen and (max-width: 700px) {
            width: 100%;
          }
        }
      }

      &-toggle {
        padding: 0 12px;
        font-size: 16px;
        min-width: 34px;
      }

      &-date {
        color: #d6d6d6;
      }

      &-name {
        color: #d6d6d6;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      color: #fbfdfe;
      height: 38px;
      background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
      border-radius: 0px 0px 4px 4px;
      width: 100%;
      padding: 0 12px;

      &-status,
      &-reason,
      span {
        color: #a1a6b0;
      }

      &-reason {
        margin: 0 0 0 4px;
      }
    }

    &-item {
      display: flex;
      margin: 12px 0;

      &-date {
        width: 134px;
        padding: 0 12px 0 34px;

        @media screen and (max-width: 700px) {
          padding: 0 12px;
        }
      }

      &-round {
        color: #d6d6d6;
        white-space: nowrap;
      }

      &-bet-amount {
        margin: 0 0 0 auto;
        width: 300px;
        text-align: right;
        padding: 0 12px;
      }

      &-amount {
        width: 300px;
        text-align: right;
        padding: 0 12px;
      }

      &.win &-amount {
        color: #73e698;
      }

      &.lose &-amount {
        color: #ff7a73;
      }
    }
  }

  &__game.notifications-view {
    &-header {
      &-row1 {
        flex-wrap: wrap;

        &-col1 {
          flex-direction: row;
          width: auto;
          flex-grow: 1;
          padding: 0;

          .history__game-header-date {
            margin-left: auto;
            padding: 0 12px;
          }
        }

        &-col2 {
          width: 100%;
          padding: 0 12px;
        }

        &-col3 {
          width: 100%;

          .history__game-header-rate {
            text-align: right;
          }
        }
      }

      &-row2 {
        flex-wrap: wrap;

        &-col1 {
          width: 100%;
        }

        &-col2 {
          width: 100%;
        }
      }
    }

    &-item {
      &-date {
        padding: 0 12px;
      }
    }
  }

  &__bet {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 8px 0 0 0;

    &-wrapper {
      border-radius: 6px 6px 0 0;
      background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
      width: 100%;
    }

    &-header {
      display: flex;
      width: 100%;
      border-radius: 6px 6px 0 0;
      min-height: 56px;
      align-items: center;
      user-select: none;
      cursor: pointer;
      flex-wrap: wrap;

      &-toggle {
        padding: 0 12px;
        font-size: 16px;
        line-height: 56px;

        @media screen and (max-width: 700px) {
          order: 1;
          line-height: 40px;
        }
      }

      &-count {
        width: 20px;
        height: 20px;
        background: #526792;
        border-radius: 12px;
        color: #fbfdfe;
        font-size: 12px;
        line-height: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 4px 0 0;

        @media screen and (max-width: 700px) {
          order: 2;
        }
      }

      &-date {
        @media screen and (max-width: 700px) {
          order: 2;
        }
      }

      &-bet {
        margin: 0 0 0 auto;
        display: flex;
        min-width: 200px;
        @media screen and (max-width: 700px) {
          order: 4;
          padding: 0 12px 12px 12px;
          min-width: auto;
        }
        @media screen and (max-width: 700px) {
          flex-wrap: wrap;
          padding: 12px;
          justify-content: flex-end;
          order: 4;
          width: 100%;
          margin: 0 0 0 auto;
        }

        &-odd,
        &-sum {
          height: 32px;
          background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 12px;
          margin: 0 0 4px 0;
        }

        &-sum {
          margin: 0 0 0 4px;
          overflow: hidden;

          > div {
            overflow: hidden;

            > span:first-child {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      &-winning {
        width: 210px;
        display: flex;
        align-items: center;
        padding: 0 12px;

        @media screen and (max-width: 700px) {
          flex-wrap: wrap;
        }

        @media screen and (max-width: 700px) {
          padding: 0 12px 12px 12px;
          order: 5;
          width: auto;
          align-items: flex-end;
        }
        @media screen and (max-width: 700px) {
          width: 100%;
          margin: 0 0 0 auto;
          justify-content: flex-end;
        }

        span {
          margin: 0 4px 0 0;
        }
      }

      &-id {
        width: 90px;
        padding: 0 12px 0 0;
        text-align: right;

        @media screen and (max-width: 1200px) {
          margin: 0 0 0 auto;
        }
        @media screen and (max-width: 700px) {
          padding: 12px;
          order: 3;
          flex-grow: 1;
        }
      }
    }

    &-content {
      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 0 12px 0;
        flex-wrap: wrap;

        &-col1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 12px;

          @media screen and (max-width: 700px) {
            width: 100%;

            > div {
              max-width: none;
              width: 100%;
            }
          }
        }

        &-col2 {
          width: 210px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 12px 0 0;
          margin: 0 0 0 auto;

          @media screen and (max-width: 700px) {
            flex-grow: 1;
            padding: 12px;
          }
        }

        &-col3 {
          min-width: 290px;
          max-width: 290px;
          width: 290px;
          padding: 0 0 0;

          @media screen and (max-width: 1200px) {
            min-width: auto;
            max-width: none;
            width: auto;
            padding: 0 12px 0 12px;
          }
        }

        &-starts,
        &-teams {
          font-size: 14px;
          line-height: 16px;
          color: #a1a6b0;
          width: 100%;
          max-width: 200px;
        }

        &-teams {
          color: #d6d6d6;
          margin: 8px 0 0 0;
        }

        &-market {
          font-size: 14px;
          line-height: 16px;
          color: #838fa3;
          width: 100%;
        }

        &-league {
          font-size: 14px;
          line-height: 16px;
          color: #fbfdfe;
          margin: 8px 0 0 0;
        }

        &-odd {
          height: 32px;
          background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0 12px;
          width: auto;
        }

        &.win &-starts,
        &.win &-teams {
          color: #73e698;
        }

        &.lose &-starts,
        &.lose &-teams {
          color: #e4606d;
        }

        &.warning &-starts,
        &.warning &-teams {
          color: #ffc107;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      color: #fbfdfe;
      height: 38px;
      background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
      border-radius: 0px 0px 4px 4px;
      width: 100%;

      span {
        color: #a1a6b0;
      }

      &-left {
        padding: 0 12px;
        color: #a1a6b0;

        .win {
          color: #73e698;
        }

        .lose {
          color: #e4606d;
        }

        .cancel {
          color: #ffc107;
        }
      }

      &-right {
        padding: 0 12px;

        .history__bet-status {
          &.win a {
            color: #73e698;
          }

          &.lose a {
            color: #e4606d;
          }

          &.cancel a {
            color: #ffc107;
          }
        }
      }
    }
  }

  &__bet.notifications-view {
    .history__bet {
      &-header {
        &-toggle {
          order: 1;
          line-height: 40px;
        }

        &-count {
          order: 2;
        }

        &-date {
          order: 2;
        }

        &-bet {
          display: flex;
          min-width: auto;
          flex-wrap: wrap;
          padding: 12px;
          justify-content: flex-end;
          order: 4;
          width: 100%;
          margin: 0 0 0 auto;
        }

        &-winning {
          flex-wrap: wrap;
          padding: 0 12px 12px 12px;
          order: 5;
          align-items: flex-end;
          width: 100%;
          margin: 0 0 0 auto;
          justify-content: flex-end;
        }

        &-id {
          margin: 0 0 0 auto;
          padding: 12px;
          order: 3;
          flex-grow: 1;
        }
      }

      &-content {
        &-item {
          &-col1 {
            width: 100%;

            > div {
              max-width: none;
              width: 100%;
            }
          }

          &-col2 {
            flex-grow: 1;
            padding: 12px;
          }

          &-col3 {
            min-width: auto;
            max-width: none;
            width: auto;
            padding: 0 12px 0 12px;
          }
        }
      }
    }
  }
}
