.bet-slip {
  position: fixed;
  bottom: 0;
  width: auto;
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  z-index: 1001;
  left: 50%;
  transform: translateX(-50%);

  .only-desktop {
    display: block;
  }

  .only-mobile {
    display: none;
  }

  @media screen and (max-width: 350px) {
    .only-desktop {
      display: none;
    }
    .only-mobile {
      display: block;
    }
  }

  &__success {
    .bet-slip-full__header {
      padding: 10px 12px !important;
      cursor: default !important;
    }

    .bet-slip-full__header-title {
      justify-content: space-between;
      margin-right: 0;
      flex-wrap: wrap;
    }

    .bet-slip__success-title-left {
      display: flex;

      .bet-slip__success-redo {
        cursor: pointer;
        margin: 0 8px 0 0;
        color: #838fa3;

        &:hover {
          color: #f2f2f2;
        }
      }
    }

    .bet-slip-full__header {
      padding: 10px 32px 10px 12px;
    }

    .bet-amount-input {
      overflow: hidden;
    }

    &-bet-amount {
      box-shadow: none;
      border: none;
      background: #1e2637;
      border-radius: 4px;
      color: #e0e0e0;
      height: 30px;
      padding: 0 8px;
      outline: none;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      white-space: nowrap;

      > div > span:first-child {
        text-overflow: ellipsis;
      }
    }

    .bet-slip-full__parlay {
      .bet-slip__success-bet-amount {
        background: #303d58;
      }
    }

    &-placed-title {
      font-size: 16px;
      line-height: 18px;
    }

    &-trx {
      width: 100%;

      a:first-child {
        font-size: 14px;
        line-height: 16px;
        text-decoration: underline;
        color: #838fa3;

        &:hover {
          color: #e0e0e0;
          text-decoration: none;
        }
      }
    }

    &-check-trx {
      border-radius: 50%;
      background: #a1a6b0;
      width: 16px;
      height: 16px;
      min-width: 16px;
      max-width: 16px;
      min-height: 16px;
      max-height: 16px;
      display: inline-flex;
      line-height: 15px;
      justify-content: center;
      align-items: center;
      color: $scolor3;
      cursor: pointer;
      margin-left: 0.3rem;
      text-decoration: none;
      font-weight: bold;
      font-size: 14px;

      &:hover {
        color: #e0e0e0;
        text-decoration: none;
      }
    }

    &-title-right {
      font-size: 14px;
      line-height: 16px;
      color: #73e698;
      padding: 12px 0;
    }

    .bet-slip-full__item-bet {
      padding: 0;

      @media screen and (max-width: 350px) {
        padding: 16px 0 0;
      }
    }

    .bet-slip-full__item-bet-id {
      font-size: 14px;
      line-height: 18px;
      color: #e0e0e0;
      text-align: right;
      padding: 0 0 8px;
      width: 100%;
    }

    .bet-slip-full__item-market {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-flow: wrap-reverse;

      .bet-slip-full__item-bet-id {
        width: auto;
      }
    }

    .bet-slip-full__item-bet-value .bet-amount-input {
      width: 100%;
      user-select: none;
    }

    .bet-slip-full__item-bet-value .bet-amount-input input {
      width: 100%;
      border-radius: 4px;
    }

    .bet-slip-full__item-bet-value .bet-amount-input .input-fiat {
      width: 100%;
    }
  }

  .input-fiat {
    position: relative;

    span {
      position: absolute;
      color: #e0e0e0;
      top: 4px;
      left: 8px;
    }

    &.prefix input {
      padding-left: 32px;
    }
  }

  &__overlay {
    background: #000;
    opacity: 0.3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
}

.bet-slip-short {
  background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #58d673 100%);
  border-radius: 8px;
  color: #1e2637;
  margin: 0 0 8px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  height: 60px;
  max-width: 500px;
  max-height: 60px;
  min-height: 60px;
  overflow: hidden;
  padding: 0 12px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 2px 16px 0px #0d111a;

  @media screen and (max-width: 500px) {
    border-radius: 0;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    position: relative;
    margin: 0 12px 0 0;
    font-weight: 500;
    font-size: 16px;
  }

  &__counter {
    background: #303d58;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #f2f2f2;
    padding: 2px 3px;
    text-align: center;
    margin: 0 0 0 4px;
    display: inline-block;
  }

  &__outcome-label {
    white-space: nowrap;
    display: flex;
    overflow: hidden;
    font-size: 16px;

    > div {
      overflow: hidden;
    }

    > div > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      margin-right: 4px;
    }

    span:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 450px) {
      > span:first-child {
        display: none;
      }
    }

    @media screen and (max-width: 350px) {
      display: none;
    }
  }

  &__outcome-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;

    &::before {
      content: "@";
      margin: 0 2px;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      margin: 0;
      height: 56px;
      max-height: 56px;

      &__title {
        font-size: 16px;
      }

      &__outcome-label {
        font-size: 16px;
      }
    }
  }
}

.bet-slip-full {
  background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
  border-radius: 8px 8px 0 0;
  max-width: 500px;
  width: 500px;
  margin: 0 0 8px;
  overflow: hidden;
  box-shadow: 0px 2px 16px 0px #0d111a;

  &.placing {
    input {
      pointer-events: none;
    }

    button {
      pointer-events: none;
    }

    .bet-slip-full__item-remove-icon {
      pointer-events: none;
    }

    .bet-slip-full__controls-reset {
      pointer-events: none;
    }

    .checkbox {
      pointer-events: none;
    }

    .bet-slip-full__item-promo {
      pointer-events: none;
    }
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(60vh - 146px);
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-right: solid 4px #1e2637;
      background: #1e2637;
    }

    &::-webkit-scrollbar-thumb {
      background: #303d58;
      border-radius: 0;
      border-right: solid 4px #1e2637;
    }

    @include media-breakpoint-down(sm) {
      max-height: calc(75vh - 50px);
    }
  }

  &__header {
    position: relative;
    width: 100%;
    padding: 21px 32px 21px 12px;
    cursor: pointer;
    user-select: none;

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #f2f2f2;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &-counter {
        background: #303d58;
        border-radius: 3px;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #f2f2f2;
        display: inline-flex;
        align-items: center;
        padding: 2px 3px;
        text-align: center;
        margin: 0 0 0 4px;
        justify-content: center;
      }
    }

    &-close-icon {
      position: absolute;
      top: 22px;
      right: 26px;
      width: 16px;
      height: 16px;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px !important;
        height: 16px;
        color: #838fa3;
        cursor: pointer;
      }
    }

    &:hover &-close-icon svg {
      color: #f2f2f2;
    }
  }

  &__message {
    background: #0c161f;
    width: 100%;
    min-height: 34px;
    font-size: 14px;
    line-height: 26px;
    color: #73e698;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    text-align: center;
    user-select: none;
    cursor: pointer;

    &--promo {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 12px;
    width: 100%;

    .checkbox {
      align-items: center;

      label {
        font-size: 14px;
        line-height: 16px;
        color: #838fa3;
      }

      .checkbox__input {
        margin-top: 0;
        cursor: pointer;
      }

      .checkbox__input .checkbox__checkmark {
        width: 12px;
        height: 12px;
        border-color: #838fa3;
      }

      .checkbox__input input:checked ~ .checkbox__checkmark {
        background: #3c4451;
        border-color: #3c4451;
      }

      .checkbox__input input:checked ~ .checkbox__checkmark:after {
        border-color: #838fa3;
      }
    }

    &-reset {
      font-size: 14px;
      line-height: 24px;
      color: #838fa3;
      user-select: none;
      white-space: nowrap;
      margin: 0 8px 0 0;

      &:hover {
        color: #f2f2f2;
        cursor: pointer;
      }
    }

    &-settings {
    }

    &-select {
      width: 100%;
      position: relative;

      &-current {
        position: relative;
        font-size: 14px;
        line-height: 16px;
        color: #838fa3;
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        user-select: none;
        height: 24px;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0 4px 0 0;
        }

        svg {
          transform: rotate(90deg);
          fill: #838fa3;
          width: 12px;
          height: 12px;
        }

        &:hover {
          /* color: #F2F2F2; */
          cursor: pointer;

          svg {
            /* fill: #F2F2F2; */
          }
        }
      }

      &-options {
        display: none;
        background: #1e2637;
        box-shadow: 0px 2px 10px rgba(30, 38, 55, 0.4);
        border-radius: 0px 0px 4px 4px;
        position: absolute;
        top: 24px;
        right: 0;
        z-index: 1010;
      }

      &-option {
        white-space: nowrap;
        font-size: 14px;
        line-height: 16px;
        width: 100%;
        color: #525c6e;
        height: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 12px;
        user-select: none;
        overflow: hidden;
        cursor: pointer;
        text-overflow: ellipsis;

        &--active {
          /* color: #838FA3; */
        }

        &:last-child {
          border-radius: 0 0 4px 4px;
        }

        &:hover {
          color: #838fa3;
          background: #2b354a;
        }
      }

      /* &--opened &-current {
        color: #F2F2F2;

        svg {
          transform: rotate(90deg);
          fill: #F2F2F2;
        }
      } */

      &--opened &-options {
        display: block;
      }
    }
  }

  &__place-bet {
    width: 100%;
    background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #58d673 100%);
    overflow: hidden;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
    flex-direction: column;
    user-select: none;
    border-radius: 0 0 8px 8px;

    @media screen and (max-width: 500px) {
      border-radius: 0;
    }

    &-title {
      color: #1e2637;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      &.loading {
        display: flex;
        align-items: center;
      }
    }

    &-caption {
      color: #525c6e;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      padding: 0 8px;

      span {
        margin: 0 2px 0 0;
      }
    }

    &--disabled {
      background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #6fcf97 100%);
    }

    &--disabled &-title {
      color: #525c6e;
    }

    &--disabled &-caption {
      color: #525c6e;
    }

    /* &-loader, &-loader:before, &-loader:after {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      animation-fill-mode: both;
      animation: load7 1.8s infinite ease-in-out;
      position: absolute;
    }
    &-loader {
      color: #fff;
      font-size: 10px;
      margin: 80px auto;
      position: relative;
      text-indent: -9999em;
      transform: translateZ(0);
      animation-delay: -0.16s;
    }
    &-loader:before,
    &-loader:after {
      content: '';
      position: absolute;
      top: 0;
    }
    &-loader:before {
      left: -3.5em;
      animation-delay: -0.32s;
    }
    &-loader:after {
      left: 3.5em;
    } */
  }

  &__footer {
    width: 100%;
    position: relative;
  }

  &__item {
    width: calc(100% - 24px);
    background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
    margin: 0 0 8px;
    padding: 18px 12px 12px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    user-select: none;
    border-radius: 4px;

    @media screen and (max-width: 350px) {
      flex-wrap: wrap;
    }

    &-error-badge,
    &-success-badge {
      position: absolute;
      width: 4px;
      border-radius: 4px 0 0 4px;
      border: 2px solid #e4606d;
      background: #e4606d;
      overflow: hidden;
      max-width: 4px;
      height: 100%;
      top: 0;
      left: 0;
    }

    &-success-badge {
      border-color: #73e698;
    }

    .bet-value {
      &.has-change {
        background: #73e698;
        border-radius: 4px;
        color: #1e2637;
        padding: 0 4px;
      }
    }

    &-remove-icon {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 10px;
      height: 10px;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 12px !important;
        height: 12px;
        color: #838fa3;
        cursor: pointer;
      }

      &.active {
        svg {
          color: #e4606d;
        }
      }
    }

    &-error &-remove-icon {
    }

    &-odd-description {
      width: calc(100% - 220px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 12px 0 0;

      @include media-breakpoint-down(sm) {
        width: 65%;
      }

      @include media-breakpoint-down(xs) {
        width: 50%;
      }

      @media screen and (max-width: 350px) {
        width: 100%;
      }
    }

    &-market {
      font-size: 14px;
      line-height: 18px;
      color: #838fa3;
      margin: 0 0 4px 0;
    }

    &-league {
      font-size: 14px;
      line-height: 18px;
      color: #e0e0e0;
    }

    &-teams {
      font-size: 14px;
      line-height: 18px;
      color: #73e698;
      margin: 12px 0 0 0;
    }

    &-promo {
      margin: 8px 0 0 0;

      .toggle {
        display: inline-flex;
        margin: 0 4px 0 0;
        max-width: 100%;

        &:before {
          width: 26px;
          min-width: 26px;
          max-width: 26px;
          height: 16px;
          background: #405071;
        }

        div {
          font-size: 14px;
          line-height: 16px;
          color: #838fa3;
          font-weight: normal;
          padding: 0;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          width: 12px;
          height: 12px;
          border: none;
          background: #1e2637;
          top: 2px;
          left: 2px;
        }

        input[type="checkbox"]:checked + span {
          left: 12px;
          background: #73e698;
        }
      }

      &.only-mobile {
        display: none;
      }

      @media screen and (max-width: 350px) {
        display: none;
        &.only-mobile {
          display: block;
        }
      }
    }

    &-bet {
      width: 220px;
      padding: 16px 0 0 0;

      @include media-breakpoint-down(sm) {
        width: 35%;
      }

      @include media-breakpoint-down(xs) {
        width: 50%;
      }

      @media screen and (max-width: 350px) {
        width: 100%;
      }
    }

    &-odd-value {
      box-shadow: none;
      border: none;
      background: #1e2637;
      border-radius: 4px;
      color: #e0e0e0;
      height: 30px;
      padding: 0 18px 0 8px;
      outline: none;
      font-size: 14px;
      line-height: 28px;
      width: 69px;
      min-width: 69px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      margin: 0 4px 0 0;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      @include media-breakpoint-down(sm) {
        margin: 0;
      }

      @media screen and (max-width: 350px) {
        margin: 0 4px 0 0;
      }

      svg {
        display: none;
        font-size: 18px;
        line-height: 18px;
        margin: 0 2px 0 0;
        position: absolute;
        right: 4px;
      }

      &.odd-change-up {
        svg {
          display: inline-block;
          color: $success;
        }
      }

      &.odd-change-down {
        svg {
          display: inline-block;
          color: $danger;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      @media screen and (max-width: 350px) {
        width: 69px;
      }
    }

    &-bet-value {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }

      @media screen and (max-width: 350px) {
        flex-wrap: nowrap;
      }

      input,
      input:hover,
      input:active,
      input:focus {
        box-shadow: none;
        border: none;
        background: #1e2637;
        border-radius: 4px;
        color: #e0e0e0;
        height: 30px;
        padding: 0 8px;
        outline: none;
        width: auto;
        font-size: 14px;
        line-height: 14px;

        &.error {
          border: 1px solid #e4606d;
        }

        &::placeholder {
          color: #525c6e;
        }
      }

      /*input:last-child {
        width: 110px;
        border-radius: 4px 0 0 4px;

        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-top: 4px;
        }
      }*/

      .bet-amount-input {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        input {
          width: 100px;
          border-radius: 4px 0 0 4px;

          @include media-breakpoint-down(sm) {
            width: 100%;
          }

          @media screen and (max-width: 350px) {
            width: 100%;
          }
        }

        @include media-breakpoint-down(sm) {
          .input-fiat {
            width: calc(100% - 44px);
          }
        }

        button {
          padding: 2px 0;
          text-align: center;
          height: 30px;
          margin: 0 0 0 1px;
          border-radius: 0 4px 4px 0;
          width: 44px;
          max-width: 44px;
          min-width: 44px;
          font-size: 14px;
          color: #fbfdfe;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 4px;
        }

        @media screen and (max-width: 350px) {
          margin-top: 0;
        }
      }
    }

    &-bet-winning {
      font-size: 14px;
      line-height: 16px;
      color: #838fa3;
      margin: 8px 0 0 0;
      text-align: right;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      min-height: 16px;

      @media screen and (max-width: 350px) {
        height: auto;
        min-height: auto;
      }

      > span {
        margin: 0 0 0 4px;
        min-width: 40px;
        text-align: right;
        display: inline-block;
        word-break: break-all;
      }
    }
  }

  &__parlay {
    background: none;
    padding: 0 12px 12px;

    &.accent {
      .bet-slip-full__item-league {
        color: #73e698;
      }
    }

    .bet-slip-full__item-remove-icon {
      display: none;
    }

    .bet-slip-full__item-league {
      margin-top: 8px;
    }

    .bet-slip-full__item-bet {
      padding: 4px 0 0;
    }

    .bet-slip-full__item-odd-value {
      background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
    }

    .bet-slip-full__item-bet-value {
      input,
      input:hover,
      input:active {
        background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
      }

      input:first-child {
        text-align: left;
      }
    }
  }
}

.dot-flashing {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #1e2637;
  color: #1e2637;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-left: 12px;
  top: 3px;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #1e2637;
  color: #1e2637;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #1e2637;
  color: #1e2637;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #1e2637;
  }
  50%,
  100% {
    background-color: #525c6e;
  }
}

/*@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}*/
