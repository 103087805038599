@import "styles/variables";

.container {
  margin: 4px 0;
  width: 100%;
  height: 34px;
  min-height: 34px;
  display: flex;
}

.icon {
  align-content: center;
  margin-right: 10px;
}

.name {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  span:first-child {
    font-size: 14px;
    line-height: 16px;
  }
  span:nth-child(2) {
    color: $gray2;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
  }
}

.amount {
  font-size: 14px;
  margin-left: auto;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.amountValue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fiatAmount {
  font-size: 14px;
  line-height: 16px;
  color: #838fa3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:before {
    content: "≈";
    display: inline-block;
    margin: 0 2px 0 0;
  }
}
