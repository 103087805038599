.wallet-modal {
  .btn-secondary.wallet-button {
    background: #40598e;
    border-radius: 5px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    height: 48px;
    padding: 0 48px;
    border: none;

    &:active,
    &:hover {
      background: #40598e;
      opacity: 0.8;
    }
  }

  .option-select {
    background: #000917;
    margin: 0 -1rem;
  }

  li.modal-nav-section {
    max-width: calc(100% / 3);
  }

  .alert {
    width: 100%;
    margin-top: 1rem;
  }

  .btn-submit {
    background: $scolor4;
    padding: 14px 43px;
    border-radius: 6px;

    &.disabled {
      background-color: #66738d !important;
      background-image: none;
      color: #ffffff;
    }
  }

  button.bt-dropdown-toggle.btn-secondary {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    border: none;
    display: flex;
    width: 100%;
    padding: 0.5rem 0.8rem 0.5rem 0;
    border-radius: 6px;
    height: 50px;
    color: #d8dadf;
    font-size: 16px;
    font-weight: normal;
    align-items: center;
  }
  .dropdown-menu {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    border-radius: 4px;
    border: none;
    max-height: 400px;
    overflow-y: auto;
  }

  @include media-breakpoint-down(xs) {
    width: auto;
  }
}

.wallet-layout {
  margin-top: 1rem;

  .form-group {
    margin-bottom: 0 !important;
  }

  .alert.alert-danger {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 100%;
      /* margin-left: 2rem; */
    }
  }

  input {
    &,
    &:hover,
    &:active {
      border-radius: 5px;
      border-color: rgb(30, 38, 55);
      outline: none;
      box-shadow: none;
    }
  }

  label {
    font-weight: normal;
  }

  &__text {
    padding: 0 4px 0 0;
    margin: 1.2rem 0 0.4rem 0;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    font-size: 1rem;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
    color: #a4b3cc;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem 0;
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #a4b3cc;
  }
}

.wallet-info {
  font-size: 0.85rem;
  color: $sportbet;
  margin: 16px 0 0 0;
}

.wallet-transfer {
  &__buttons {
    display: flex;
    align-items: flex-end;
    gap: 0.8rem;
    > div {
      width: 100%;
      &:nth-child(2) {
        width: auto;
        button {
          background: #1e2637;
          padding: 4px 12px;

          &:not(:disabled):active,
          &:focus {
            background: #1e2637;
          }
          &:hover,
          &:active {
            background: #252f44;
          }
        }
      }
    }
    &__button {
      background: $scolor4;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 4px 1rem;
      min-height: 40px;
      border-radius: 6px;
    }
  }
}

.social-link {
  position: relative;
  max-width: 276px;
  height: 50px;
  border-radius: 5px;
  margin: 12px auto 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: none;
    color: #fff;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.alert-enter {
  animation: slideDown 0.3s ease-out forwards;
}

.alert-exit {
  animation: slideUp 0.3s ease-in forwards;
}
