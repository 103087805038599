@mixin translateScale($scale) {
  -webkit-transform: translate(50%, 0%) scale($scale);
  transform: translate(50%, 0%) scale($scale);
}

.slider-wrapper {
  @media screen and (max-width: 300px) {
    display: none;
  }
}

.swiper-pagination {
  position: absolute;
  right: 0;
  bottom: 8px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;

  &-bullet {
    display: inline-block;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;

    &-active {
      opacity: 1;
    }
  }

  @media screen and (max-width: 300px) {
    display: none;
  }
}

.slider-image-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 5px;

  a {
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: cover;

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }
}

.carousel-indicators {
  margin-bottom: 8px;

  @media screen and (max-width: 450px) {
    margin-bottom: 0;
  }
}

.swiper-button {
  &-prev,
  &-next {
    z-index: 2;
    position: absolute;
    cursor: pointer;
    height: 2.25rem;
    width: 2.25rem;
    bottom: 16px;
    top: unset;
    background: #ffffff33;
    border-radius: 4px;
    display: flex;

    &::after {
      content: "";
      display: block;
      height: 1.5rem;
      width: 1.5rem;
      margin: auto;
    }
  }

  &-next {
    right: 16px;

    &::after {
      background-image: url("/images/controls/arrowRight.svg");
    }
  }

  &-prev {
    left: unset;
    right: 60px;

    &::after {
      background-image: url("/images/controls/arrowLeft.svg");
    }
  }
}

.category-swiper {
  > .swiper-button {
    &-prev,
    &-next {
      transform: translate(0%, -50%);
      top: 50%;
      height: 100%;
      max-width: 46px;
      width: 100%;
      &::after {
        transform: translate(50%, -50%);
        position: absolute;
        margin: 0;
        top: 40%;
        right: 50%;
      }
      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    &-next {
      right: -5px;
      background: linear-gradient(
        -90deg,
        rgba(21, 27, 40, 1) 40%,
        rgba(21, 27, 40, 0) 100%
      );
    }

    &-prev {
      left: -5px;
      background: linear-gradient(
        90deg,
        rgba(21, 27, 40, 1) 40%,
        rgba(21, 27, 40, 0) 100%
      );
    }
    &-disabled {
      display: none;
    }
  }
}

.slide-auto-width {
  > .swiper-button {
    &-prev,
    &-next {
      &::after {
        top: 50%;
      }
    }
  }
  .swiper-slide {
    width: auto;
  }
}

.get-bonus-button {
  min-width: 168px;
  max-width: 168px;

  position: absolute;
  bottom: 39px;
  z-index: 1;
  right: 50%;

  padding: 14px 30px;
  border-radius: 12px;
  border: 2px solid #fff8d6;

  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #1e2637;
  text-transform: uppercase;

  background: linear-gradient(180deg, #ffea7e 9%, #ffda44 44%, #fabd30 65.5%);
  box-shadow: 0px 6px 16px 0px #01170733, 0px 4px 0px 0px #fff0a666 inset,
    0px -4px 0px 0px #fff0a666 inset !important;

  @include translateScale(1);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    @include translateScale(1);
  }
  50% {
    @include translateScale(0.9);
  }
  100% {
    @include translateScale(1);
  }
}

@keyframes pulse {
  0% {
    @include translateScale(1);
  }
  50% {
    @include translateScale(0.9);
  }
  100% {
    @include translateScale(1);
  }
}
