.rules-content {
  padding: 24px 24px;
  background: #0c161f;

  h1 {
    font-size: 24px;
    line-height: 28px;
    color: #fbfdfe;
    margin: 0 0 12px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 18px;
    line-height: 28px;
    color: #838fa3;
    margin: 24px 0 16px;
    text-transform: uppercase;
  }

  ul,
  ol {
    padding: 0;
    margin: 0 16px;

    li {
      font-size: 16px;
      line-height: 20px;
      color: #838fa3;
      margin: 0 0 8px;
      padding: 0;
    }
  }

  i.rules-page-updated {
    font-size: 14px;
    line-height: 16px;
    color: #838fa3;
    margin: 36px 0 0;
    display: block;
  }
}

.radio-tabs.rules-nav-tabs {
  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;

    li {
      &,
      &:first-child {
        width: 100%;
        margin: 0 0 4px !important;
      }
    }
  }
}
