$accentColor: $sportbet;

@mixin scoreGridColumns($cols: 4) {
  grid-template-columns:
    minmax(150px, 1fr)
    repeat($cols, minmax(35px, auto));

  @include media-breakpoint-down(sm) {
    grid-template-columns:
      minmax(100px, 1fr)
      repeat($cols, auto);
  }
}

.event-status {
  background: transparent;

  .card-body {
    background: $body-bg-v2;
    padding: 0;
  }

  .event-info {
    background: $body-bg-v2;

    .card-header {
      margin-bottom: 0;
    }

    .card-body {
      max-height: 136px;
      overflow-y: scroll;
    }
  }
}

//- Score Boards
//todo: edit and finish
.score-grid-container {
  max-width: 660px;
  margin: 0 auto;
}

.score-grid {
  display: grid;
  margin: 0 auto;
  width: 100%;
  border-collapse: separate;
  color: #fff;
  font-size: 14px; // to use em in child elements
  // grid-template-columns is dynamic, should change according to sport.
  // todo: update
  grid-template-columns:
    minmax(150px, 1fr)
    repeat(5, minmax(35px, auto));

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.score-grid thead,
.score-grid tbody,
.score-grid tr {
  display: contents;
}

.score-grid .head-row {
  background: transparent;
}

.score-grid .body-row {
  background: $scolor2;
}

.score-grid .score-grid-cell {
  padding: 3px 6px;
  text-align: center;
  font-size: 1.14em;
}

.score-grid .score-head-cell {
  padding: 3px 6px 6px;
  text-align: center;
  font-size: 0.85em;

  &.icon > img {
    display: inline-flex;
    max-width: 100%;
  }

  &.total {
    white-space: nowrap;
  }
}

.score-grid .score-grid-cell,
.score-grid .score-head-cell {
  white-space: nowrap;

  &.col-main {
    text-align: left;
  }
}

.score-grid .score-grid-cell.total {
  color: $accentColor;
}

.score-grid .body-row .score-grid-cell {
  background: #1e2637;
  margin: 0 0 6px 0;
}

.score-grid .score-grid-row:last-child .score-grid-cell {
  margin-bottom: 0;
}

.score-grid .body-row .score-grid-cell:first-child,
.score-grid .body-row .score-grid-cell.first {
  border-radius: 5px 0 0 5px;
}

.score-grid .body-row .score-grid-cell:last-child,
.score-grid .body-row .score-grid-cell.last {
  border-radius: 0 5px 5px 0;
}

.score-grid {
  .score-grid-cell.accent,
  .score-head-cell.accent {
    color: $accentColor;
  }
}

.score-grid .score-grid-cell.col-main {
  padding-left: 0.75em;
}

.score-grid .serve-indicator {
  width: 5px;
  height: 5px;
  background: #838fa3;

  &.active {
    background: $accentColor;
  }
}

// TeamBlock styles
.score-grid {
  .team-block {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .team-block > .serving {
    margin: 0 0.17em 0 0;
  }

  .team-kit {
    display: flex;
    margin: 0 0.37em;

    &:first-child {
      margin-left: 0;
    }
  }

  .team-name {
    flex: 1 1 1px;
    margin: 0 0.37em;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }
  }
}

.score-grid-cols-2 {
  @include scoreGridColumns(1);
}

.score-grid-cols-3 {
  @include scoreGridColumns(2);
}

.score-grid-cols-4 {
  @include scoreGridColumns(3);
}

.score-grid-cols-5 {
  @include scoreGridColumns(4);
}

.score-grid-cols-6 {
  @include scoreGridColumns(5);
}

.score-grid-cols-7 {
  @include scoreGridColumns(6);
}

.score-grid-cols-8 {
  @include scoreGridColumns(7);
}

.score-grid-cols-9 {
  @include scoreGridColumns(8);
}

.score-grid-cols-10 {
  @include scoreGridColumns(9);
}

.score-grid-cols-11 {
  @include scoreGridColumns(10);
}

.score-grid-cols-12 {
  @include scoreGridColumns(11);
}

.score-grid-cols-13 {
  @include scoreGridColumns(12);
}

.score-grid-cols-14 {
  @include scoreGridColumns(13);
}

.score-grid-cols-15 {
  @include scoreGridColumns(14);
}

.score-grid.tableTennis {
  // 5 cols (with total)
  @include scoreGridColumns(4);
}

.score-grid.basketball {
  // 6 cols (with total)
  @include scoreGridColumns(6);
}

.score-grid.hockey {
  // 4 cols (with total)
  @include scoreGridColumns(4);
}

.score-grid.tennis {
  // 6 cols (with total)
  @include scoreGridColumns(6);
}

.score-grid.volleyball {
  // 5 cols (with total)
  @include scoreGridColumns(5);
}

.score-grid.baseball {
  // 11 cols
  @include scoreGridColumns(11);
}

.score-grid.eSport {
  // 4 cols
  @include scoreGridColumns(4);
}

.score-grid.soccer {
  // 4 cols
  @include scoreGridColumns(4);
}

.score-board-wrap {
  min-height: 100px;
  padding: 0.45em 0.45em 1em;
  background-color: $body-bg-v2;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: auto;

  &--no-bg {
    background-image: none !important;
  }

  &.soccer {
    background-image: url("/img/sport-bg/soccer.png");
  }

  &.basketball {
    background-image: url("/img/sport-bg/basketball.png");
  }

  &.tableTennis {
    background-image: url("/img/sport-bg/table-tennis.png");
  }

  &.hockey {
    background-image: url("/img/sport-bg/hockey.png");
  }

  &.tennis {
    background-image: url("/img/sport-bg/tennis.png");
  }

  &.volleyball {
    background-image: url("/img/sport-bg/volleyball.png");
  }

  &.baseball {
    background-image: url("/img/sport-bg/baseball.png");
  }

  &.esports {
    background-image: url("/img/sport-bg/esports.png");
  }

  &.rugbyunion,
  &.rugbyleague {
    background-image: url("/img/sport-bg/rugby.png");
  }

  &.cricket {
    background-image: url("/img/sport-bg/cricket.png");
  }

  @include media-breakpoint-down(sm) {
    background-position: center center;
    // background-size: 100% 100%;
  }
}

//- Score Boards End

.disclaimer-tooltip {
  position: relative;
  user-select: none;

  svg {
    color: #838fa3;
    margin: 0 0 0 8px;

    &:hover {
      color: #fff;
      cursor: pointer;
    }
  }

  .disclaimer-tooltip-text {
    position: absolute;
    background: #000917;
    max-width: 250px;
    width: 250px;
    padding: 4px 8px;
    border-radius: 4px;
    z-index: 1;
    font-size: 0.75rem;
    top: 25px;
    left: calc(50% - 25px);

    &:after {
      content: "";
      background: #000917;
      width: 4px;
      height: 4px;
      top: -2px;
      left: 27px;
      position: absolute;
      transform: rotate(45deg);
    }
  }
}

.match-header {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: -0.5rem;
  padding: 28px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &.afl {
    background-image: url("/img/sport-bg/afl.png");
  }

  &.baseball {
    background-image: url("/img/sport-bg/baseball.png");
  }

  &.basketball {
    background-image: url("/img/sport-bg/basketball.png");
  }

  &.boxing {
    background-image: url("/img/sport-bg/mma.png");
  }

  &.mma {
    background-image: url("/img/sport-bg/mma.png");
  }

  &.cricket {
    background-image: url("/img/sport-bg/cricket.png");
  }

  &.esports {
    background-image: url("/img/sport-bg/esports.png");
  }

  &.handball {
    background-image: url("/img/sport-bg/handball.png");
  }

  &.hockey {
    background-image: url("/img/sport-bg/hockey.png");
  }

  &.rugbyunion {
    background-image: url("/img/sport-bg/rugby.png");
  }

  &.rugbyleague {
    background-image: url("/img/sport-bg/rugby.png");
  }

  &.soccer {
    background-image: url("/img/sport-bg/soccer.png");
  }

  &.tableTennis {
    background-image: url("/img/sport-bg/table-tennis.png");
  }

  &.tennis {
    background-image: url("/img/sport-bg/tennis.png");
  }

  &.volleyball {
    background-image: url("/img/sport-bg/volleyball.png");
  }

  &__team {
    width: calc(50% - 65px);
    height: 42px;
    display: flex;
    align-items: center;
    height: 42px;
    background: rgba(30, 38, 55, 0.6);
    overflow: hidden;
    padding: 0 12px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      font-size: 18px;
      color: #dee1e3;
    }

    &-kit {
      width: 76px;
      height: 76px;
      top: -17px;
      background: $scolor2;
      position: absolute;
    }

    &--home {
      justify-content: flex-end;
      border-radius: 0 40px 40px 0;
      padding-right: 28px;
    }

    &--home &-kit {
      right: 0;
    }

    &--away {
      justify-content: flex-start;
      border-radius: 40px 0 0 40px;
      padding-left: 28px;
    }

    &--away &-kit {
      left: 0;
    }
  }

  &__starts {
    text-align: center;
    width: 130px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #dee1e3;
    padding: 0 12px;

    &-date,
    &-time {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  @include media-breakpoint-down(sm) {
    & {
      padding: 18px 0;

      &__team {
        width: calc(50% - 43px);
        height: 32px;

        span {
          font-size: 14px;
        }
      }

      &__starts {
        width: 86px;

        &-date,
        &-time {
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
}
