.sport-icon {
  height: 37px;
  width: 50px;
  background-image: url("/img/sports-svg-sprite.svg");
  background-size: 398px 398px;
  margin: 0 auto;

  &.tennis-icon {
    background-position: -220px 0;
  }

  &.table-tennis-icon {
    background-position: 47px -133px;
  }

  &.basketball-icon {
    background-position: -264px -130px;
  }

  &.baseball-icon {
    background-position: -42px 0;
  }

  &.soccer-icon {
    background-position: -263px -220px;
  }

  &.hockey-icon {
    background-position: -263px -88px;
  }

  &.football-icon {
    background-position: -86px -178px;
  }

  &.mma-icon {
    background-position: -132px -88px;
  }

  &.boxing-icon {
    background-position: -130px -354px;
  }

  &.esports-icon {
    background-position: -130px -222px;
  }

  &.cricket-icon {
    background-position: -307px 0;
  }

  &.afl-icon {
    background-position: -173px -266px;
  }

  &.rugbyunion-icon {
    background-position: -42px -45px;
  }

  &.rugbyleague-icon {
    background-position: -353px 0;
  }

  &.my-bets-icon {
    background-position: 0 -310px;
  }

  &.volleyball-icon {
    background-position: 1px -222px;
  }

  &.handball-icon {
    background-position: -41px -307px;
  }
}
