.table {
  font-size: 0.9rem;

  td {
    color: darken(white, 20);
  }

  &.table-sm {
    thead {
      th {
        font-size: 0.8rem;
      }
    }
  }

  &.table-dark {
    background: $sel-bg;
  }

  &.fixed-header {
    thead th {
      position: sticky;
      top: 0;
      background: $sel-bg;
      box-shadow: 0px 1px 0px $scolor3;
      border: none;
      z-index: 999;
    }
  }

  &.hover {
    tbody tr:hover td {
      background: $sel-hover-bg;
      color: $sel-hover;
    }
  }

  .table-col-cmd {
    width: 50px;

    svg {
      display: none;
    }
  }

  tr:hover {
    .table-col-cmd {
      svg {
        display: block;
        cursor: pointer;
      }
    }
  }

  &-sort {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
  }
}
