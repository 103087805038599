.login-title {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.85rem;
  color: #838fa3;
  width: 100%;
}

.login-view-outer-container {
  padding: 50px 0 100px;
  overflow: hidden;
}

.login-view-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 450px;
  // min-height needed to properly place ball icons
  min-height: 800px;
  margin: 0 auto;
  @include media-breakpoint-down(xs) {
    padding: 0 20px;
  }

  .ball-el {
    position: absolute;
    opacity: 0.03;
    z-index: -1;

    > img {
      display: block;
      max-width: 100%;
      object-fit: cover;
    }

    &.tennis {
      width: 82px;
      height: 82px;
    }

    &.basket {
      width: 167px;
    }

    &.rugby {
      width: 165px;
    }

    &.soccer {
      width: 155px;
    }
  }
}

.joinUs-view-wrapper {
  color: $white2;
  margin: 0 0 25px;

  .joinUs-view-header {
    margin-bottom: 30px;

    button[class="close"] {
      font-size: 1.8rem;
      line-height: 0.9;
    }
  }

  .joinUs-view-advantages {
    // hack to match design
    margin: 0 -25px;
  }
}

.joinUs-advantages-list {
  display: flex;
  gap: 10px;
  width: auto;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 27px 0;
  font-size: 14px;
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    #1c2434 33.12%,
    rgba(21, 27, 40, 0) 100%
  );

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    margin: 16px 0;
  }

  > .item {
    @include media-breakpoint-down(xs) {
      margin: 8px 0;
    }

    .advantage-icon {
      width: 24px;
      height: 24px;
      background-image: url("/images/icon-check-1.png");
      background-size: cover;
      margin-right: 4px;
    }

    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
}

.form-group {
  position: relative;
  margin-bottom: 1.25rem;

  .invalid-feedback,
  .invalid-feedback-sum {
    position: absolute;
    left: 0;
    bottom: -1.3rem;
    color: $danger;
    font-size: 0.875rem;
    text-align: start;
  }
}

.joinLogin-form-container {
  .joinLogin-form-bonuses {
    margin: 0 0 12px;
  }

  width: 100%;

  .alert {
    margin: 0 1px;
    font-size: 14px;
  }

  .joinLogin-form-code {
    margin-bottom: 12px;
    cursor: pointer;

    h6 {
      color: $gray2;
      font-weight: 500;
      font-size: 0.875rem;
      margin-bottom: 0;
      margin-right: 0.5rem;
    }

    &__input {
      width: 100%;
      position: relative;
    }
  }

  .bonusErrorInfo {
    font-size: 0.875rem;
    position: absolute;
    bottom: -1.3rem;
    left: 0;
    color: $danger;
  }

  .code-activation {
    position: relative;
    margin-bottom: 12px;

    input {
      padding-right: 44px;
      max-width: 450px;
      width: 100%;
      height: 48px;
      line-height: 48px;
      background: $scolor2;
      border-radius: 6px;
      border: none;

      &.error {
        border: 1px solid #ff8379;
      }

      &::placeholder {
        color: #525c6e;
        font-size: 0.9rem;
      }
    }

    .inputButton {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      font-size: 16px;
      right: 12px;
      cursor: pointer;
      user-select: none;
      color: $white2;

      &.loading {
        color: #525c6e;
        transition: 1s color linear;
      }
    }
  }

  .joinLogin-form-socials {
  }

  .title-secondary {
    margin: 0 0 1rem;
  }

  .login-form {
    // as per design

    .login-form-licence-rules {
      margin: 2rem 0 1.875rem;
    }

    @include media-breakpoint-down(sm) {
      .login-form-submit-wrapper,
      .login-form-licence-rules {
        width: 100%;
        max-width: initial;
      }
    }
  }
}

.lgn-title-secondary {
  font-size: 1rem;
  font-weight: 500;
  color: $gray2;
  text-transform: uppercase;
  margin: 0 0 1rem;
}

.lgn-social-login-wrapper {
  > .lgn-title-secondary {
    margin: 0 0 1rem;
  }
}

.lgn-social-login-actions {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  flex-wrap: wrap;
  justify-content: center;

  &__social {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    > .social-link {
      margin: 0 !important;

      &:not(:last-of-type) {
        margin-right: 8px !important;
      }
    }
  }

  &__wallets {
    display: flex;
    align-items: center;
    justify-content: space-around;

    > .login-button {
      &:not(:last-of-type) {
        margin-right: 8px !important;
      }
    }
  }

  .separator {
    display: block;
    width: 2px;
    margin: 0 8px;
    background: #838fa3;
    height: 48px;
    border-radius: 2px;
    opacity: 0.5;
  }

  .mobile-links {
    > .login-button {
      margin: 0 0 16px 0 !important;

      &:not(:last-of-type) {
        margin-right: 8px !important;
      }
    }
  }
}

.login-form button.btn-resend-confirmation {
  color: #d8dadf !important;

  &:hover {
    color: #d8dadf !important;
  }
}
