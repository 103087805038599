.system-down-popup {
  .system-down {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem 2rem;

    &__loudspeaker {
      font-size: 5.2rem;
    }

    &__title {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.2rem;
      color: #fff;
      width: 100%;
      text-align: center;
    }

    &__message {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.85rem;
      color: #838fa3;
      width: 100%;
    }

    &__links {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }

    .login-message {
      text-align: center;
    }
  }

  .modal-header {
    padding-bottom: 0;
  }

  .modal-body {
    padding-top: 0;
  }
}
