@import "src/styles/variables";

.container {
  background-color: #1e2637;
  border-radius: 8px;
  padding: 16px;
}

.header {
  color: $white2;
  text-transform: uppercase;
}

.text {
  color: #a4b3cc;
}

.icon {
  margin-right: 10px;
}
