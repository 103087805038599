.breadcrumbs {
  display: flex;
  justify-content: flex-start;

  &__item {
    margin: 0 4px 0 0;
    height: 38px;
    color: #d8dadf;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    user-select: none;
    min-width: 0;

    &:last-child {
      margin: 0;
    }

    @media screen and (max-width: 700px) {
      &:not(.breadcrumbs__back-button) {
        display: none;

        &:last-child {
          display: flex;
        }
      }
    }
  }

  &__back-button {
    background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
    width: 38px;
    min-width: 38px;
    cursor: pointer;

    &:hover {
      background: linear-gradient(
        152.08deg,
        lighten(#354361, 5%) 0%,
        lighten(#303d58, 10%) 100.47%
      );
    }
  }

  &__dropdown {
    button {
      display: flex;
      align-items: center;
      padding: 0 14px;
      background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
      border: none;
      border-radius: 4px;
      height: 38px;
      min-width: 100%;
      color: #a1a6b0 !important;
      cursor: pointer;

      & span:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:hover,
      &.open {
        background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
        color: #fbfdfe !important;
      }
    }

    .dropdown-menu {
      background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
      border-radius: 4px;
      padding: 8px 0;
      border: none;
    }

    .dropdown-item {
      border-radius: 0;
      border: none;
      font-size: 14px;
      line-height: 16px;
      background: transparent;
      min-height: 32px;
      height: auto;
      color: #a1a6b0;
      box-shadow: none !important;
      white-space: normal;
      min-width: 240px;

      > a {
        padding: 0;
      }

      &:hover,
      &:active,
      &.active {
        color: #fbfdfe;
        background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      }
    }
  }

  &__single {
    background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
    padding: 0 14px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      font-size: 12px;
    }

    &:not(.current):hover {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      color: #fbfdfe !important;
      cursor: pointer;
    }
  }

  &__event-live {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 54px;

    &-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      width: 100%;
    }

    &-indicator {
      background: #e4606d;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      min-width: 8px;
      max-width: 8px;
      min-height: 8px;
      max-height: 8px;
    }

    &-status {
      margin: 0 0 0 4px;
    }

    &-score {
      color: #73e698;
      margin: 0 0 0 8px;
      font-size: 12px;
    }

    &-title {
      font-size: 14px;
      line-height: 18px;
      margin: 4px 0 0 0;
      width: 100%;
    }
  }
}
