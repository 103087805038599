.endpoints-list {
  &__selected {
    position: sticky;
    top: 45px;
    z-index: 2;
    background-color: $sel-active-bg;
    color: $sel-active;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
    border-bottom: 1px solid #303d58;

    &-quantity {
      display: flex;
      align-items: center;

      span {
        text-decoration: underline;
        font-size: 0.75rem;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__table {
    thead {
      tr {
        th {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          input[type="checkbox"] {
            cursor: pointer;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          input[type="checkbox"] {
            cursor: pointer;
          }
        }

        &.include td {
          background: $sel-active-bg;
        }

        &.exclude td {
          opacity: 0.5;
        }

        &.selected td {
          background: $sel-hover-bg;
          opacity: 1;
        }
      }
    }
  }
}
