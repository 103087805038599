.events-date {
  grid-area: date;
  padding-left: $league-spacer-x;
  text-align: left;
}

.live-stream-badge {
  grid-area: liveStream;

  .badge {
    font-size: 0.7rem;
    background-color: $scolor6;
    border-color: $scolor6;
    border-radius: 4px;
    color: $body-bg;
    width: 100%;
    height: 17px;
  }
}

.bet-ml {
  grid-area: ml;
}

.bet-hdp {
  grid-area: hdp;
}

.bet-total {
  grid-area: total;
}

.event-time,
.event-more,
.live-stream-badge {
  padding: 0 map-get($spacers, 1);
}

.event-time {
  grid-area: time;
  height: 100%;

  .badge {
    font-size: 0.7rem;
    background: #fbfdfe;
    border-radius: 4px;
    color: $body-bg;
    width: 100%;
  }
}

.event-more {
  grid-area: more;
  height: 100%;

  //font-size: 0.8rem;
  text-align: center;

  .badge {
    //color: $header-color;
    font-size: 0.7rem;
    background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #58d673 100%);
    border-radius: 4px;
    color: $body-bg;
    width: 100%;
  }
}

.event-home,
.event-away {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;

  .badge {
    border-radius: 4px;
    font-size: 0.7rem;
    height: 1.1rem;
  }
}

@include media-breakpoint-up(md) {
  .event-home,
  .event-away,
  .event-draw {
    margin-left: 5px;
  }
}

// .event-away,
// .event-draw {
//   border-top: 1px dashed $body-color;
// }

.event-home {
  grid-area: home;
}

.event-away {
  grid-area: away;
}

.event-draw {
  grid-area: draw;
  height: 100%;
  line-height: 28px;
}

.bet-ml1 {
  grid-area: ml1;
}

.bet-ml2 {
  grid-area: ml2;
}

.bet-mlx {
  grid-area: mlx;
}

.bet-hdp1 {
  grid-area: hdp1;
}

.bet-hdp2 {
  grid-area: hdp2;
}

.bet-total1 {
  grid-area: total1;
}

.bet-total2 {
  grid-area: total2;
}

.bet-total,
.bet-total1,
.bet-total2 {
  display: none;
}

.events-container {
  color: $header-color;
  font-size: 0.875rem;
  text-align: center;
  display: grid;
  grid-template-areas: "date date ml hdp total";
  padding: 4px 0 0 0;
}

.event-short {
  display: grid;
  align-items: end;
  padding: 4px 0;
  margin: 0;
  color: lighten($sel-color, 10);
  background-color: $body-bg-v2;
  font-size: 0.875rem;
  grid-template-areas: "time home ml1 hdp1 total1" "more away ml2 hdp2 total2" "liveStream draw mlx . .";

  .event-time {
    color: lighten($sportbet, 25);
  }

  @include hover {
    // background: #415a82;
    background: lighten($sel-bg, 1);
  }
}

.unvisible {
  display: none;
  visibility: hidden;
}

.event-short {
  .event-short-no-odds {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
    pointer-events: all;

    &.no-draw {
      &:after {
        top: -15px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .event-short-no-odds.total-column {
      display: none;
    }
  }

  @media screen and (max-width: 300px) {
    .event-short-no-odds {
      display: none;
    }
  }
}

.events-container,
.event-short {
  grid-template-columns: 3rem 30fr 10fr 20fr;
}

@include media-breakpoint-up(sm) {
  .events-container,
  .event-short {
    grid-template-columns: 3rem 50fr 10fr 20fr 20fr;
  }

  .bet-total {
    display: block;
  }

  .bet-total1,
  .bet-total2 {
    display: flex;
  }
}

@include media-breakpoint-up(md) {
  .events-container,
  .event-short {
    grid-template-columns: 3rem 70fr 10fr 20fr 20fr;
  }

  .event-short {
    padding: 4px;
  }
}

@include media-breakpoint-up(lg) {
  .events-container,
  .event-short {
    grid-template-columns: 3rem 90fr 10fr 20fr 20fr;
  }
}
