.paging {
  height: 32px;
  display: flex;
  justify-content: flex-end;
  margin: 12px 12px 12px 0;

  @include media-breakpoint-down(md) {
    height: 32px;
    margin: 6px;
  }

  a {
    /* @include media-breakpoint-down(md) {
      &.other {
        display: none;
      }
    } */

    &,
    &:hover {
      line-height: 32px;
      text-decoration: none;
      background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
      color: #d8dadf;
      font-size: 14px;
      padding: 0 12px;

      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 32px;
      }
    }

    &:hover:not(.current) {
      color: #fff;
    }

    &.current {
      background: #405071;
    }

    &.prev,
    &.next {
      padding: 0 4px;

      @include media-breakpoint-down(md) {
        padding: 0 12px;
      }
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
      padding: 0 16px;

      @include media-breakpoint-down(md) {
        padding: 0 12px;
      }
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
      padding: 0 16px;

      @include media-breakpoint-down(md) {
        padding: 0 12px;
      }
    }
  }
}

.page-link.active {
  font-weight: normal;
  z-index: unset;
  color: #d8dadf;
}
