$scolor1: #0c161f;
$scolor2: #1e2637;
$scolor3: #303d58;
$scolor4: #405071;
$scolor5: #526792;
$scolor6: #40598e;
$scolor7: #333f4a;
$scolor8: $scolor3;
$scolor9: #1f2535;
$font1: #d8dadf;
$font2: #a0a9b9;
$font3: #fff;
$btnfont2: #fff;
$btnfont3: #fff;
$bodyback: #151b28;
$gray2: #838fa3;
$white: #fff;
$white2: #fbfdfe;

$primary1: #dc3545;
$primary2: #73e698;

$card-bg: #fff !default;

$body-bg: #000917 !default;
$body-color: #a1a6b0 !default;
$sportbet: $primary2 !default;
$sel-color: $body-color !default;
$sel-bg: $scolor2 !default;
$sel-hover: #e0e0e0 !default;
$sel-hover-bg: #293143 !default;
$sel-active: #fff !default;
$sel-active-bg: #525c6e !default;
$sel-top: $sportbet !default;
$sel-top-bg: $sel-bg !default;
$container-bg: $body-bg !default;
$accent-color: #73e698;
$live-color: $primary1;
$focused-bg: $scolor4;

$popup-bg: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);

$theme-colors: (
  "primary": $sel-active,
  "secondary": $sel-active-bg,
  "danger": #ff7a73,
  "success": $accent-color,
);

$league-color: $sel-active !default;
$league-bg: lighten($sel-bg, 10) !default;

$header-color: darken($league-color, 16) !default;
$header-bg: $league-bg !default;
$table-border-color: $league-bg !default;

$event-bg: $card-bg !default;
$event-border-color: $body-bg !default;
$event-active-bg: $sel-color !default;

$modal-header-color: #fff !default;
$modal-header-bg: $scolor9;
$close-color: $sel-color;

$dark: $sel-bg !default;

$link-color: $sel-color !default;
$link-hover-color: $sel-hover !default;

$enable-rounded: false !default;
$enable-shadows: true !default;

$navbar-dark-color: $sel-color !default;
$navbar-dark-hover-color: $sel-hover !default;
$navbar-dark-active-color: $sel-active !default;

$nav-link-padding-x: 0.5rem !default;

$nav-tabs-link-active-color: $sel-active !default;
$nav-tabs-link-active-bg: $sel-active-bg !default;

$nav-tabs-border-color: #343b47 !default;

$nav-pills-link-active-color: $sel-active !default;
$nav-pills-link-active-bg: $sel-active-bg !default;

$card-spacer-y: 0.375rem !default;
$card-spacer-x: 0.75rem !default;

$league-spacer-y: 0.25rem !default;
$league-spacer-x: 0.75rem !default;

$container-max-widths: (
  lg: 960px,
  xl: 1140px,
);

$body-bg-v2: $scolor1; // rename later
$border-radius-md: 4px !default;
$btn-height: 30px;
