.container {
  font-size: 14px;
  position: relative;
}

.input {
  height: 36px;
  width: 100%;
  padding: 8px 30px 8px 40px;
}

.input:focus-visible {
  outline: none;
}

.input,
.container {
  background-color: #1e2637;
  border-radius: 6px;
  border: none;
  color: #a4b3cc;
}
.input::placeholder {
  color: #a4b3cc;
}

.searchIcon,
.crossIcon {
  display: inline;
  position: absolute;
}

.searchIcon {
  left: 14px;
  top: 12px;
}

.crossIcon {
  cursor: pointer;
  right: 9px;
  top: 9px;
}

.light {
  background-color: #303d58;
  .input {
    background-color: #303d58;
  }
}
