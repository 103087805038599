.footer-wrapper {
  color: $sel-active;
  background: transparent;

  .nav-link {
    padding: 0;

    @media screen and (max-width: 800px) {
      padding: 10px 0;
    }
  }

  .dropdown {
    width: 180px;
    max-width: 100%;

    .dropdown-toggle {
      width: 100%;
      background: $sel-active-bg;
      border: 0 !important;
      outline: none;
      border-radius: $border-radius-md;
      background: #1b2232;

      &:hover,
      &:active {
        background: #1e2637;
      }
    }

    &.show .dropdown-toggle {
      background: #1e2637;
    }

    .dropdown-menu {
      //right: 0;
      border-radius: $border-radius-md;
      background: #1b2232;

      .dropdown-item {
        padding: 0.25rem 0.9rem;
        background: #1b2232;
        font-size: 14px;

        &:hover {
          background: #1e2637;
        }
      }
    }
  }
}

.bottom-footer-part {
  color: $sel-color;
}

.footer-group-label {
  font-size: 1.2rem;
  font-weight: 600;
}

.partner-logo,
.contact-link {
  opacity: 0.7;
  color: #e0e0e0;
  cursor: pointer;

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  @media screen and (max-width: 800px) {
    padding: 10px 0;
  }
}

.info-link-federbet {
  display: block;
  height: 30px;
  background: url("/img/federbet.png") no-repeat;
  background-size: contain;
}
