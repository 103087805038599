.about-us {
  &__feedback {
    resize: none;
    min-height: 150px;
    overflow-y: scroll;

    &::-webkit-scrollbar-thumb {
      background: $select-bg-2;
    }
  }
}
