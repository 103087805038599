@import "src/styles/variables";

.total {
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 12px 0 0 0;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    min-height: 62px;
    height: auto;
    padding: 8px 0;
  }
}

.title {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: #838fa3;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  span {
    display: block;
  }
}

.amountWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 16px;
}

.amountValue {
  white-space: nowrap;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fiatAmount {
  font-size: 14px;
  line-height: 16px;
  color: $gray2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:before {
    content: "≈";
    display: inline-block;
    margin: 0 4px;
  }
}
