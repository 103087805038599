.self-exclusion {
  &-form {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }

  &-info-box {
    display: flex;
    text-align: left;
    padding: 0.75rem;
    border: 1px solid #ffc107;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &-info-text {
    color: #ffc107;
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  &-period-text {
    font-size: 1rem;
    color: $white;
    margin-bottom: 1.5rem;
    text-align: left;
  }

  &-counter {
    font-size: 1.25rem;
    padding: 0.75rem;
    background: $scolor2;
    color: $white;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &-appreciation {
    font-size: 1rem;
    color: $gray2;
    margin: 0;
  }
}

.extend-selector {
  &-container {
    width: 100%;

    button.bt-dropdown-toggle.btn-secondary {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      border: none;
      width: 100%;
      border-radius: 4px;
      height: 3rem;
      font-size: 1rem;
      font-weight: normal;
    }

    span {
      color: $font1;
      text-decoration: none;
      margin-left: 0.5rem;
    }

    button {
      text-decoration: none;
      padding: 0 0.5rem;
    }

    .dropdown-menu {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      border-radius: 4px;
      border: none;

      .dropdown-item {
        color: $font1;
        background: transparent;
        border: none;

        &:hover,
        &:active,
        &.active {
          background: $scolor6;
        }
      }
    }
  }

  &-menu {
    height: 20rem;
    overflow: scroll;
  }
}
