.tutorial-tooltip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
}

.tutorial-tooltip {
  position: absolute;
  bottom: calc(100% + 12px);
  left: 0;
  width: 75%;
  border-radius: 5px;
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
  color: $scolor3;
  background: #f2f3f4;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }

  &__content {
    margin: 0 0 12px 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      user-select: none;
    }

    button {
      padding: 0 24px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 5px;
      background: $scolor4;
    }
  }

  &:after {
    content: "";
    position: absolute;
    background: #f2f3f4;
    width: 12px;
    height: 12px;
    bottom: -6px;
    left: 12px;
    transform: rotate(45deg);
  }
}

.checkbox {
  display: flex;

  &__input {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-top: 1px;

    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
    }

    .checkbox__checkmark {
      width: 18px;
      height: 18px;
      background: transparent;
      border: 1px solid $body-color;
      border-radius: 2px;
      top: 3px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    input:checked ~ .checkbox__checkmark {
      border: 1px solid $scolor2;
      background: $scolor2;
    }

    input:checked ~ .checkbox__checkmark:after {
      content: "";
      display: block;
      width: 4px;
      height: 8px;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      margin-bottom: 2px;
      transform: rotate(45deg);
    }
  }

  label {
    margin: 0 0 0 0.75rem;
    font-size: 0.875rem;
    text-align: left;
    user-select: none;
    color: $gray2;
    cursor: pointer;

    a {
      color: #73e698;
      text-decoration: underline;
    }
  }
}
