.sports-navigation {
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 0 4px;
  margin: 4px 0 0 0;

  &__item {
    display: flex;
    background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
    padding: 10px !important;
    border-radius: 4px;
    margin: 0 4px 0 0;
    align-items: center;

    svg {
      height: 22px;
      width: 22px;
    }

    &_icon {
      padding: 0 5px;
    }

    &_text {
      white-space: nowrap;
      font-size: 14px;
    }

    &:hover {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    }
  }
}
