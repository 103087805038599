.bet-header {
  color: $league-color;
  text-align: center;
  flex: 1;
}

.all-bets-section {
  .market-view-body {
    // background: $container-bg;
    background: $body-bg-v2;
    padding: 7px 14px 14px;
  }
}

.market-bets {
  display: flex;
  padding: 7px 0 0;
  width: 100%;
  //margin: map-get($spacers, 1) 0;
}

.match-header {
  color: white;
  margin-top: 12px !important;

  .text-danger {
    color: $live-color !important;
  }

  .match-header-time {
    display: flex;
    align-items: center;

    span {
      height: 33px;
    }
  }

  @media screen and (min-width: 992px) {
    display: flex;
    align-items: center;

    .match-header-time {
      margin-right: 12px;
    }
  }
}

.match-header {
  .event-time {
    display: block;
  }
}

.edge-info {
  color: $sel-color;
}

.live-badge {
  display: inline-block;
  width: 60px;
  position: absolute;
  top: 16px;
  left: 12px;
  height: 1rem;
  z-index: 1;

  &:before {
    content: "Live";
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    z-index: 2;
    top: -2px;
    left: 0;
    width: 100%;
    text-align: center;
  }

  &:after {
    content: "";
    position: absolute;
    height: 1.5rem;
    left: 5px;
    right: 5px;
    top: -2px;
    transform: skewX(-20deg);
    background: #dc3545;
    z-index: 1;
  }
}

.no-odds-message {
  &:before {
    content: "";
    display: block;
    background-image: url("/img/clock.svg");
    width: 100%;
    height: 70px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 12px;
    margin-top: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .match-header {
    .event-time {
      display: inline;
    }
  }
}
