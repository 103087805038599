.buttons {
  border-top: 1px solid #405071;
  padding: 1rem 0;
  display: flex;
  gap: 0.5rem;
  width: 100%;
  justify-content: space-between;

  button {
    width: 100%;
    height: 46px;
  }
}
