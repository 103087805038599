.featured-event {
  width: 270px;
  max-width: calc(100vw - 38px);
  min-width: 236px;
  background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
  border-radius: 4px;
  padding: 0;
  margin: 0 8px 0 0;
  user-select: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  @include media-breakpoint-up(lg) {
    min-width: 270px;
  }

  &:last-child {
    margin: 0;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    color: #a1a6b0;

    &__sport-icon {
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
      position: relative;

      .sport-icon {
        transform: scale(0.35);
        opacity: 0.5;
        margin: 0;
        position: absolute;
        top: -14px;
        left: -18px;
      }
    }

    &__league {
      font-size: 11px;
      line-height: 14px;
      margin-left: 4px;
      white-space: nowrap;
      max-width: 45%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__event-time {
      font-size: 10px;
      margin-left: auto;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0 0 8px;
    }

    &__status-text {
      font-size: 10px;
      white-space: nowrap;
      margin: 0 0 0 4px;
    }

    &__live-stream {
      width: 15px;
      min-width: 15px;
      height: 8px;
      background: #a1a6b0;
      border-radius: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 4px;

      svg {
        color: $scolor2;
        font-size: 4px;
      }
    }
  }

  &-bet {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    margin: 0 0 8px 0;

    .featured-event-bet__row {
      display: flex;
      margin-left: 4px;
      width: calc(100% - 4px);

      .featured-event-bet__row-item {
        margin-right: 4px;

        button {
          width: 100%;
          margin: 0 !important;
        }

        &--unavailable {
          pointer-events: none;

          .bet-odd {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 21px;

            img {
              height: 17px;
            }
          }
        }
      }

      &.featured-event-bet__row--title {
        font-size: 10px;
        color: #7f7f7f;
        text-align: center;
      }
    }

    &--2x {
      .featured-event-bet__row-item {
        width: 50%;
      }
    }

    &--3x {
      .featured-event-bet__row-item {
        width: calc(100% / 3);
      }
    }
  }

  &--live {
    .featured-event-header__event-time {
      padding-right: 10px;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        background: #db4437;
        border-radius: 50%;
        position: absolute;
        top: 4px;
        right: 0;
      }
    }

    .featured-event__teams {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 12px 0px;
      height: 56px;
      overflow: hidden;

      .featured-event-team {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        max-width: 100%;
        text-align: left;
      }

      .featured-event__teams-separator {
        display: none;
      }

      .team-block {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .team-kit {
        svg {
          width: 10px;
          height: 12px;
          margin: 0 2px 4px;
        }
      }

      .team-name {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .featured-event-team__score {
      display: block;
      color: #68e690;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &--prematch {
    .featured-event-header__event-time {
      color: #68e690;
    }

    .featured-event__teams {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      height: 56px;
      overflow: hidden;

      .featured-event-team {
        flex: 1;
        max-width: calc(50% - 8px);

        &:first-child {
          text-align: right;

          .team-block {
            flex-direction: row-reverse;
          }
        }
      }

      .featured-event__teams-separator {
        display: block;
        background-color: #fff;
        min-width: 4px;
        height: 1px;
        margin: 0 4px 0;
      }

      .team-block {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .team-kit {
        svg {
          width: 10px;
          height: 12px;
          margin: 0 2px 4px;
        }
      }

      .team-name {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .featured-event-team__score {
      display: none;
    }
  }
}
