.main-menu {
  margin-bottom: -0.5rem;
  margin-left: 1.5rem;
  font-size: 1.2rem;

  @include media-breakpoint-down(lg) {
    margin-left: 0;
    font-size: 1rem;
  }

  .navbar-nav {
    height: 3rem;
    display: flex;
    align-items: stretch;

    .nav-item {
      margin: 0 0.8rem;
      display: flex;
      align-items: stretch;

      @include media-breakpoint-down(lg) {
        margin: 0 0.5rem;
      }

      .nav-link {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.2rem;
        padding: 0 !important;
        color: #787b82;
        border: none;

        .live-indicator {
          margin: 0 0 0 6px !important;
        }

        &:hover,
        &.active {
          color: darken(white, 20);
        }

        &.live-menu-option {
          position: relative;
          color: darken(white, 10);

          .live-menu-option-text {
            z-index: 2;
            margin: 0 1.5rem 2px;
          }

          .live-menu-option-bg {
            position: absolute;
            height: 1.5rem;
            left: 5px;
            right: 5px;
            transform: skewX(-20deg);
            background: $live-color;
          }

          &:hover {
            .live-menu-option-bg {
              background: lighten($live-color, 10);
            }
          }

          &.active {
            .live-menu-option-bg {
              // transform: none;
              background: $live-color;
            }
          }
        }

        &.active {
          color: white;
        }
      }
    }
  }

  .language-selector-container {
    .btn {
      background: transparent;

      &:active {
        background: transparent !important;
      }
    }
  }
}

.header-container {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
  background: #151b28;

  &_shadow {
    box-shadow: 0px 2px 16px rgba(8, 10, 5, 0.8);
  }

  .dropdown-menu {
    border-radius: 4px;

    .dropdown-item {
      padding: 0.25rem 8px;
    }
  }

  @media screen and (max-width: 768px) {
    min-height: 50px;
  }
}

.balance-container {
  font-size: 13px;
  line-height: 13px;
}

.balance-group {
  white-space: nowrap;
}

.dropdown-menu {
  min-width: 1rem;
}

.signup-btn,
.signin-btn {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  text-align: center;
  line-height: 16px;
  font-size: 14px;

  &__highlighted {
    -webkit-animation: pulseSignUp 2s infinite;
    animation: pulseSignUp 2s infinite;
  }
}

.signin-btn {
  color: #fff;
  background: none;
  margin: 0 8px 0 0;

  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 100%
      ),
      rgba(72, 98, 154, 0.1) !important;
  }
}

.signup-btn {
  color: #000000;
  background: linear-gradient(180deg, #a4f4b7 0%, #6ff68c 0.01%, #58d673 100%);

  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 100%
      ),
      linear-gradient(180deg, #a4f4b7 0%, #6ff68c 0.01%, #58d673 100%) !important;
  }
}

.user-balance-container {
  $border-radius: 4px;
  width: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  flex-wrap: nowrap;

  @media screen and (max-width: 800px) {
    width: unset;
  }

  .separator {
    height: 1px;
    background: #526792;
    margin: 10px 10px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    margin-bottom: 0;

    input {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    background: #526792;
    left: 4px;
    bottom: 2px;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.42, 0, 0, 1.68);
  }

  input:checked + .slider:before {
    transform: translateX(12px);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(159, 180, 222, 0.4) 115.38%
      ),
      #f0f8ff;
  }

  .btn-secondary {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .btn-group {
    padding: 8px 10px;
    display: flex;

    .btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }

  .btn {
    border: 0;
    color: #838fa3 !important;
    background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
  }

  .selected {
    color: #fbfdfe !important;
    background: #526792;
  }

  @include media-breakpoint-up(sm) {
    width: auto;
  }

  .input-group-text,
  button {
    font-size: 14px;
    font-weight: 500;
  }

  .input-group-text {
    color: white;
  }

  .btn-separated-addon {
    cursor: pointer;
    border-right: 1px solid $body-bg;

    a {
      display: flex;
      align-items: center;
    }
  }

  .btn-refill {
    color: $accent-color;

    &:hover,
    &:active {
      color: $accent-color !important;
    }
  }

  .input-group-prepend > .input-group-text {
    border: 0;
    border-radius: $border-radius 0 0 $border-radius;
  }

  .input-group-append > button {
    border: 0;
    border-radius: 0 $border-radius $border-radius 0;
  }

  .input-group-prepend,
  .input-group-append {
    margin: 0;
  }

  .input-group-prepend:first-child {
    margin-left: auto;
  }
}

.settings-popover {
  padding-bottom: 1.5rem;

  .settings-header {
    font-weight: 600;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    color: lighten($body-color, 20);
  }

  .radio-button-group {
    margin-bottom: 0.5rem;

    .btn {
      padding: 0.5rem 1rem;
    }

    .btn-secondary {
      color: $body-color;
    }
  }
}

.login-status-container {
  width: auto;

  .user-actions {
    justify-content: flex-end;
  }

  .btn {
    border: 0;
  }

  li {
    display: flex;
  }

  @media screen and (max-width: 800px) {
    flex-grow: 1;
    justify-content: flex-end;

    .user-actions {
      flex-grow: 1;
      justify-content: center;

      .user-balance-container {
        margin-left: auto;
      }

      .widget-counters {
        margin-left: auto;
      }

      > button:first-child {
        margin-left: auto;
      }
    }
  }
}

.header-logo {
  background-image: url("/img/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  align-self: stretch;
  max-height: 40px;
  max-width: 130px;

  @include media-breakpoint-down(lg) {
    margin-right: 0.3rem !important;
  }

  @media screen and (min-width: 992px) {
    margin-top: 5px;
  }

  @include media-breakpoint-up(sm) {
    width: 130px;
  }

  @media screen and (max-width: 800px) {
    width: 40px;
    max-width: 40px;
  }
}

.language-selector-container {
  .dropdown-item {
    display: flex;
    align-items: center;
  }
}

.history-nav-link {
  display: block !important;
  height: 16px;
  padding: 2px 0 0 8px;
}

@-webkit-keyframes pulseSignUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulseSignUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
