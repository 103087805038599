.group-events-body {
  $button-color: #556584;
  background: none !important;

  .group-event-item {
    margin-bottom: 4px;
    background: #0c161f;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
    border-radius: 4px;

    &:first-child {
      border-radius: 0px 0px 4px 4px;
    }

    .league-name {
      svg {
        display: inline;
        width: 16px;
        height: 16px;
      }
    }

    &:only-child,
    &:last-child {
      margin-bottom: 0;
    }
  }

  .bet-button {
    margin: 1px;

    .bet-text {
      color: white;
    }
  }
}

.bet-text {
  text-align: right;
  color: #ccd5e6;
  white-space: nowrap;
  font-weight: 300;
  margin-right: map-get($spacers, 1);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bet-odd-text {
  text-align: left;
  color: $accent-color;
  font-weight: 400;
  margin-left: map-get($spacers, 1);
}

.tick-up {
  color: $primary2;
}

.tick-down {
  color: $primary1;
}

.tick-up,
.tick-down {
  margin-right: 4px;
}

.bet-parlay {
  color: #fff;
  border-bottom: 1px solid $scolor3;
  position: relative;

  &__close-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  button {
    color: $live-color;
  }
}

.bets-legs {
  font-size: 0.85rem;
}

.bet-parlay-item {
  padding: 10px;
  font-size: 0.8rem;
  background: $focused-bg;

  &__time {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .bet-odd-value {
    padding: 2px 0;
    border-radius: 2px;

    &.odd-change-up {
      padding: 2px 4px;
      background-color: $success;
    }

    &.odd-change-down {
      padding: 2px 4px;
      background-color: $danger;
    }
  }

  .bet-value-change {
    padding: 2px 4px;
    border-radius: 2px;
    background-color: $warning;
    color: black;
  }
}

.bet-parlay-indicator {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 0px;
  width: 100%;
  box-shadow: 0px 0px 0.5em #000;
}

.bet-button {
  margin: 4px;
  padding: 0;
  display: flex;
  background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
  border-radius: 4px;
  height: 26px;
  line-height: 26px;
  border: 0;
  box-shadow: none !important;
  outline: none !important;
  justify-content: center;

  &.details {
    height: auto;
    padding: map-get($spacers, 1);
    flex: 1;
    max-width: 100%;
  }

  &:hover {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
  }

  &:active {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
  }

  &.short {
    .bet-text {
      color: $accent-color;
    }

    .bet-odd-text {
      color: #fff;
    }
  }

  &.active {
    background: #f2f2f2 !important;
    color: $scolor1 !important;

    .bet-text,
    .bet-odd-text {
      color: $scolor1 !important;
    }
  }
}
