.assets {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 175px;
  margin: 10px 0 0;
  padding: 0 5px 0 0;
  overflow-y: scroll;
}

.message {
  font-size: 14px;
}
