.notifications-onsite {
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 9999;
  overflow: hidden;
  width: 450px;
  max-width: 100%;
}

.notification-onsite {
  background: #303d58;
  border-radius: 6px 0 0 6px;
  padding: 16px 16px 23px 16px;
  width: 450px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 0 8px;
  position: relative;
  user-select: none;
  cursor: pointer;
  right: -450px;
  transition: right 0.3s ease-in;

  @media screen and (max-width: 500px) {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 6px;
    width: calc(100% - 20px);
  }

  &.open {
    right: 0;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .notification-language {
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 6px 6px;
    margin: 0 0 4px 0;
    display: inline-flex;

    .flag-icon {
      width: 20px;
      height: 14px;
      margin: 0 4px 0 0;
      font-size: 18px;
    }

    span {
      font-size: 15px;
      line-height: 21px;
      color: #d8dadf;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin: 0 16px 0 0;
    font-size: 24px;

    img {
      -webkit-user-drag: none;
    }
  }

  &__message {
    flex-grow: 1; // width: calc(100% - 46px);
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;

    &-title {
      font-size: 15px;
      line-height: 18px;
      color: #d8dadf;
      width: calc(100% - 20px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-text {
      font-size: 13px;
      line-height: 15px;
      color: #d8dadf;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #a1a6b0;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  &__progress {
    position: absolute;
    height: 7px;
    width: 75%;
    bottom: 0;
    right: 0;
    border-radius: 6px;
    background: #1e2637;
    transition: width 0.2s ease;
  }
}

.notifications-popup {
  max-width: 624px;

  &__filter {
    display: flex;
    margin: 16px 0 8px;
    justify-content: flex-end;

    > div {
      margin-bottom: 8px;
    }

    > div:last-child {
      margin: 0 0 0 8px;
      width: 50%;

      @media screen and (max-width: 500px) {
        width: 100%;
        margin: 0;
      }
    }

    .input-search {
      width: 50%;
    }
  }

  &__mark-read {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
    width: 100%;

    span {
      color: #bdc0c7;
      cursor: pointer;
      font-size: 14px;
      user-select: none;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .paging {
    margin-bottom: 0;
    margin-right: 0;

    a {
      background: #405071;

      &.current {
        background: #40598e;
      }
    }
  }
}

.notification-card {
  background: #405071;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  padding: 8px 16px;
  width: 100%;

  &:last-child {
    margin: 0;
  }

  &__icon {
    font-size: 20px;
    padding: 8px 16px 0 0;
  }

  &__content {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
  }

  &__title {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 6px 0 0 0;

    h4 {
      font-size: 18px;
      line-height: 18px;
      margin: 0 0 8px;
    }
  }

  &__date {
    color: #a1a6b0;
    font-size: 12px;
  }

  &__message {
    font-size: 14px;
    line-height: 18px;
    max-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;

    &--short {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 70%;
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }

    a {
      text-decoration: underline;

      &:hover {
        color: #fbfdfe;
      }
    }

    ol {
      margin-left: 8px;
      padding-left: 16px;

      li {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &--open {
    .notification-card__message {
      max-height: none;
    }
  }
}
