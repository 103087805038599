.license-agreement {
  &__modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 1rem;
    margin: 0 -1rem 1rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;

    &-icon {
      background: inherit;
      width: 60px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      font-size: 28px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      padding-top: 14px;
    }
  }

  label {
    cursor: pointer;
    user-select: none;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > button:first-child {
      margin-right: 8px;
    }
  }
}
