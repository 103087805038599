.dropdown-menu {
  background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);

  .dropdown-item {
    color: $sel-hover;

    &:hover {
      background: #526792;
    }

    &:active,
    &.active {
      background: #526792;
    }

    &.hash-route:active,
    &.active.hash-route {
      background: none;
    }

    &.hash-route.active-link:active,
    &.active-link.hash-route {
      background: #526792;
    }
  }
}

.option-select {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    font-weight: 600;
    padding: 0 1rem;
    cursor: pointer;
    color: lighten($sel-active-bg, 20);

    &.active-option,
    &:hover {
      color: white;
    }
  }
}
