$select-bg-2: #212a3d;
$select-border-radius: 6px;
$select-bg: #1e2637;

.bonus-selector {
  position: relative;
  color: $white;
  background-color: $scolor4;
  z-index: 1;
  border-radius: $select-border-radius;

  .rs__value-container {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    overflow: hidden;

    > input {
      width: 0.1px;
    }

    > .bonus-value {
      width: 100%;
    }
  }

  .rs__option {
    padding: 0;
    background-color: $scolor4;
    cursor: pointer;

    &[aria-disabled="false"] {
      cursor: pointer;
    }

    &:last-of-type {
      // fix for border-radius on rselect__menu
      border-bottom-left-radius: $select-border-radius;
      border-bottom-right-radius: $select-border-radius;
    }

    &:hover,
    &:active,
    &:focus {
      background: #485a80 !important;
    }
  }

  .rs__control {
    background: $scolor4;
    border: 0 !important;
    box-shadow: none !important;
    overflow: hidden; // fix for overflowing gradients
    cursor: pointer;

    &--is-focused {
      border: 0 !important;
      outline: none !important;
    }

    &:hover,
    &:active,
    &:focus {
      background: #485a80 !important;
    }
  }

  .rs__indicator-separator {
    display: none !important;
  }

  .rs__menu {
    margin: 0;
    margin-top: 0.25rem;
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .rs__menu-list {
    max-height: initial;
    padding: 0;
    border-radius: $select-border-radius;
    background-color: $scolor4;
  }

  .rs__menu-notice {
    padding: 1rem;
  }

  .rs__dropdown-indicator {
    width: 36px;
    height: 36px;
    margin: 0 12px 0 0;
    border-radius: 50%;
    color: #a1a6b0 !important;
    cursor: pointer;
  }

  .rs__control--menu-is-open .rs__dropdown-indicator {
    > svg {
      transform: rotate(180deg);
    }
  }

  .rs--is-disabled {
    opacity: 0.9;

    .rs__dropdown-indicator {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }
}

.bonus-selection-entity {
  display: flex;
  padding: 9px 12px;
  color: $white2;
  text-align: start;

  &--icon {
    display: flex;
    align-items: center;
    margin: 0 18px 0 0;

    > div {
      width: 30px;
      height: 30px;
      overflow: hidden;

      > img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
  }

  &--title {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 2px;
    align-items: center;
  }

  &--text {
    font-size: 0.75rem;
    font-weight: normal;
    color: #c8d0df;
  }
}
