@import "styles/variables.scss";

.balanceButtons {
  display: flex;
  justify-content: center;
  padding: 0 8px 8px 8px;
}

.balanceButton {
  display: flex;
  border: none;
  height: 30px;
  color: $gray2;
  font-weight: 400 !important;
  background: #354361;
  justify-content: center;
  align-items: center;
  width: 50%;

  &:nth-child(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:nth-child(2) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.assets {
  height: 220px;
  overflow-y: scroll;

  button {
    div {
      display: grid !important;
      grid-template-columns: 1fr 24px 40px !important;
    }
  }
}

.message {
  color: $white;
  font-size: 14px;
  padding: 8px;
  margin: 0;
}

.search {
  margin: 0 8px 10px;
}

.filters {
  padding: 8px 8px 0 8px;
}

.filter {
  display: flex;
  font-size: 14px;
  white-space: nowrap;
  justify-content: space-between;
  color: #fbfdfe;
  margin-bottom: 0.5rem;
  user-select: none;
}
