.challenge {
  &__form {
    form {
      display: flex;
      flex-direction: column;
    }

    .btn-main {
      width: 100%;
      margin: 0;
    }

    input {
      border-radius: 6px;

      &:disabled {
        background: #1e2637
      }
    }

    button {
      border-radius: 6px;
    }

    .form-group {
      margin-bottom: 0;
    }

    .invalid-feedback {
      position: relative;
      bottom: 0 !important;
    }

    .settings__form-footer {
      margin-top: 24px;
    }
  }

  &__info {
    color: #838FA3;
    text-align: center;
  }

  &__password {
    svg {
      color: #303d58;
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      right: 16px;
      top: 22px;
      -webkit-user-select: none;
      user-select: none;
    }

    &.active {
      svg {
        color: #838fa3
      }
    }

    input[type="password"].invalid {
      background-image: none !important;
    }
  }
}