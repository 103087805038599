.bet-modal {
  .modal-body {
    padding: 0 1rem 1rem;
  }

  .modal-footer {
    padding: 1rem;
  }

  &__form {
    margin: 1rem 0 0;

    &-amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $sel-bg;
      border-radius: 5px;

      input {
        &,
        &:hover,
        &:active,
        &:focus {
          background: none;
          border: none;
        }
      }
    }

    &-win-amount {
      display: flex;
      margin: 0.5rem 0 0 0;

      .input-group-text {
        border-radius: 5px;
        border: none;
        margin-right: 0.5rem;
      }

      input {
        border-radius: 5px;
        border: none !important;
      }
    }

    &-buttons {
      margin: 0.5rem 0 0 0;

      button {
        margin: 0 0.5rem 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &__buttons {
    margin: 0 !important;
    width: 100%;

    button {
      border: none;

      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
}

.accordion.bet-accordion {
  margin: 0;
  background: none;

  .card-header {
    border: none !important;
    margin-bottom: 1px;
    background: $scolor2; // $focused-bg;
    border-radius: 5px;
    height: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    &.open {
      border-radius: 5px 5px 0 0;
    }

    h4 {
      font-size: 1rem;
    }
  }

  .card-body {
    background-color: $scolor2; // 4
    border: 1px solid $scolor2; // 4
    border-radius: 0 0 5px 5px;
    padding: 0.5rem 0.75rem 0;
  }
}

.bet-placed {
  .accordion {
    .card-body {
      padding: 0;

      .bet-parlay:last-child {
        border: none;
      }
    }
  }

  .transaction-info {
    margin: 0.5rem 0 0 0;
    display: flex;
    font-size: 0.9rem;
    align-items: center;

    &__faq {
      border-radius: 50%;
      background: #a1a6b0;
      width: 16px;
      height: 16px;
      line-height: 15px;
      text-align: center;
      color: $scolor3;
      cursor: pointer;
      margin-left: 0.3rem;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        background: #fff;
      }
    }
  }

  .modal-footer {
    padding-top: 0;
    display: flex;
    justify-content: center;

    button {
      width: 150px;
    }
  }
}
