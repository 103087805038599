.live-stream {
  text-align: center;
  position: relative;

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    .live-stream-paused {
      z-index: 2;

      .player__controls-overlay {
        opacity: 1 !important;
      }
    }
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__spinner {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__message {
    background: rgb(30, 38, 55);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &.alternate {
      min-height: 600px;
      background: #08121c;
    }
  }

  #youtube-player,
  #twitch-player {
    position: relative;
  }

  .player__controls {
    svg {
      color: #a1a6b0;
      user-select: none;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    &--center {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      user-select: none;

      svg {
        font-size: 24px;
      }
    }

    &--right {
      background: #343b47;
      border-radius: 2px;
      padding: 4px 8px;
      opacity: 0;
      transition: 0.3s ease opacity;
      position: absolute;
      right: 20px;
      bottom: 16px;
      z-index: 3;
      user-select: none;
      display: flex;

      > div,
      > svg {
        margin: 0 8px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &--right {
        > div,
        > svg {
          margin: 0;
        }
      }

      &-fullscreen {
        display: none;
      }
    }

    &.live-stream-paused .player__controls--right,
    &:hover .player__controls--right {
      opacity: 1;
      transition: 0.3s ease opacity;
    }

    &-overlay {
      background: #343b47;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

@include media-breakpoint-up(md) {
  .event-status {
    &.live-stream-open:not(.live-stream-fullscreen) {
      display: flex;
      justify-content: space-between;

      .event-info-section {
        flex-basis: calc(50% - 8px);
      }

      .live-stream-section {
        flex-basis: calc(50% - 8px);
        margin: 0 0 12px 12px;
        height: 100%;
        width: 50%;
      }
    }
  }
}

.live-stream-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 6px;

  span {
    background: #5f6673;
    width: 22px;
    height: 14px;
    border: 1px solid #5f6673;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: #000917;
      font-size: 8px;
    }
  }
}
