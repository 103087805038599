.settings-container {
  font-size: 18px;
  cursor: pointer;
  padding-top: 3px;

  &:hover {
    color: white;
  }
}

.general-settings {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 16px 0;

  &__col {
    width: 50%;

    &:first-child {
      padding: 0 4px 0 0;
    }

    &:last-child {
      padding: 0 0 0 4px;
    }

    @media screen and (max-width: 520px) {
      width: 100%;
      &:first-child,
      &:last-child {
        padding: 0;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 16px;
    color: #a1a6b0;
    margin: 0 0 8px 0;

    @media screen and (max-width: 520px) {
      margin: 8px 0;
    }
  }

  .language-selector-container {
    width: 100%;

    button.bt-dropdown-toggle.btn-secondary {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      border: none;
      width: 100%;
      border-radius: 4px;
      height: 48px;
      color: #d8dadf;
      font-size: 16px;
      font-weight: normal;
    }

    .dropdown-menu {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      border-radius: 4px;
      border: none;

      .dropdown-item {
        color: #d8dadf;
        background: transparent;
        border: none;

        &:hover,
        &:active,
        &.active {
          background: #40598e;
        }
      }
    }
  }
}

.fiat-settings__currencies {
  display: flex;
  padding: 20px 0 20px 20px;
  flex-wrap: wrap;
  gap:5px;

  > label {
    width: 45px;
    margin: 0 20px 0 0;
    color: #d8dadf !important;

    .check:before {
      border-color: #d8dadf !important;
    }

    .check:after {
      background: #d8dadf !important;
    }
  }
}

.fiat-settings__area {
  display: flex;
  justify-content: space-evenly;
  margin: 16px 0 0;
}

.fiat-settings__disclaimer {
  color: #73e698;
  text-align: right;
  width: 100%;
  margin: 16px 0 0;
  font-size: 14px;
}
