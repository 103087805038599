.promo {
  &-modal {
    &--no-risk-bet-claimed {
      max-width: 600px;
    }

    &__icon {
      width: 100%;
      text-align: center;
      margin: 0 0 24px;
    }

    &__message {
      h2 {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #d8dadf;
        width: 100%;
        margin: 0 0 18px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        text-align: center;
        color: #838fa3;
      }
    }

    &__tiles-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 10px 0 8px 0;
      overflow-y: hidden;
      overflow-x: auto;

      @media screen and (max-width: 600px) {
        justify-content: flex-start;
      }
    }

    &__tile {
      min-width: 146px;
      width: 146px;
      height: 180px;
      background: $scolor2;
      border-radius: 4px;
      user-select: none;
      margin: 0 18px 0 0;
      position: relative;
      padding: 0 8px;

      &-counter {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: $scolor3;
        background: linear-gradient(268.92deg, #73e698 2.13%, #00c1f4 96.24%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        justify-content: center;
        position: absolute;
        top: -10px;
        right: -10px;
      }

      &-icon {
        margin: 32px 0 0 0;
        width: 100%;
        text-align: center;
      }

      &-message {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #838fa3;
        margin: 30px 0 0 0;
      }
    }

    &__deposit-button {
      background: linear-gradient(268.92deg, #73e698 2.13%, #00c1f4 96.24%);
      border-radius: 4px;
      font-size: 16px;
      line-height: 44px;
      height: 44px;
      white-space: nowrap;
      text-align: center;
      text-transform: uppercase;
      color: $scolor2;
      user-select: none;
      cursor: pointer;
      margin: 16px auto 10px;
      max-width: 236px;
      width: 100%;

      &:hover {
        background: linear-gradient(268.92deg, #00c1f4 2.13%, #73e698 96.24%);
      }
    }
  }

  &-container {
    $container: &;
    overflow: hidden;

    &__title {
      color: #fbfdfe;
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      margin-top: 1.25rem;
    }

    &__header {
      height: 40px;
      margin-top: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-action {
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          margin: 0;
          margin-bottom: 3px;
        }
      }

      #{$container} {
        &__title {
          margin: 0;
        }
      }
    }
  }

  &-cards {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;

    &__item {
      flex: 1;
      background: linear-gradient(180deg, #ffffff 0%, #e2eaff 100%);
      border-radius: 0.25rem;
      padding: 1.125rem;
      display: flex;
      justify-content: center;
      cursor: pointer;

      @media screen and (max-width: 430px) {
        padding: 1.125rem 0;
      }
    }
  }

  &-grid {
    margin: 1rem 0 0 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
    gap: 0.75rem;
    padding-bottom: 7.5rem;

    @include media-breakpoint-down(md) {
      margin: 0.5rem 0 0 0;
    }

    &__card {
      width: 100%;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      margin: 0 0 0.5rem 0;
      text-decoration: none;
      background: #202737;
      border-radius: 0.25rem;

      &:hover {
        text-decoration: none;
      }

      img {
        width: 100%;
        height: 100%;
      }

      &-body {
        width: 100%;
        padding: 0 1rem;
        padding-bottom: 0.75rem;

        a,
        .btn-success {
          font-weight: 500;
        }
      }

      &-title {
        font-size: 1.1rem;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        text-transform: uppercase;
        height: 42px;
      }

      &-benefits {
        display: flex;
        flex-direction: column;
        padding-left: 1.2rem;

        li {
          font-size: 1rem;
          font-weight: 400;
        }
      }

      .promo-grid__card-zoom {
        overflow: hidden;
        margin-bottom: 1rem;
        position: relative;

        img {
          transition: transform 0.2s;
          min-height: 160px;
          object-fit: cover;
        }
      }

      &:hover {
        .promo-grid__card-zoom {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  &-info {
    &__modal {
      &-content {
        width: 100%;
        max-width: 400px;
        margin: auto;
      }
    }

    &__image {
      height: 160px;
      position: relative;
      width: 100%;

      &-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &__title {
      font-size: 1.1rem;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &__terms {
      color: #ffffff;
      text-decoration: underline;
    }
  }
}
