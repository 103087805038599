.social-link {
  width: 42px;
  height: 42px;
  border-radius: 8px;
  margin: 0 !important;
  overflow: hidden;
  background: #405071;
  color: #c8d0df;

  &:hover {
    &.google {
      background: #ff483a;
    }

    &.facebook {
      background: #3650a2;
    }

    &.twitter {
      background: #000;
    }

    &.anchor {
      background: #3650a2;
    }

    &.wombat {
      background: #ff483a;
    }
  }

  &__icon {
    font-size: 16px !important;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    left: 0;

    svg {
      font-size: 20px;
    }
  }

  &__text {
    text-transform: none !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    margin: 0 0 0 8px;
  }
}

.login-back {
  background: $scolor2;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  svg {
    font-size: 16px;
  }
}

.login-message {
  font-size: 14px;
  color: #838fa3;

  button {
    padding: 0 4px;
    background: none;
    border: none;
    color: #73e698;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.login-form {
  margin: 20px auto;
  max-width: 450px;
  width: 100%;

  .alert {
    margin: 0 1px;
    font-size: 14px;
  }

  input[type="text"],
  input[type="password"] {
    max-width: 450px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: $scolor2;
    border-radius: 6px;
    margin: 10px 0;

    &::placeholder {
      color: #525c6e;
    }
  }

  .login-password {
    position: relative;

    input {
      padding-right: 44px;
    }

    svg {
      position: absolute;
      right: 0;
      top: calc(50% - 10px);
      font-size: 20px;
      right: 16px;
      cursor: pointer;
      user-select: none;
      color: $scolor3;

      &:hover {
      }
    }

    &.active svg {
      color: #838fa3;
    }
  }

  .login-forgot-password {
    text-align: right;
    font-size: 0.875rem;
    margin-bottom: 0.6rem;

    a {
      color: #838fa3;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  button {
    width: 100%;
    height: 48px;
    color: #0c161f !important;

    &:disabled {
      color: #525c6e !important;
    }

    // &.btn-secondary {
    //  color: #fff !important;
    // }
  }

  &.fb-recovery {
    max-width: 400px;

    .login-title {
      color: #fbfdfe;
      font-size: 1rem;
      margin: 0;
    }

    .login-message {
      color: #838fa3;
    }

    .recovery-timer {
      color: #fbfdfe;
    }

    input[type="email"],
    input[type="text"],
    input[type="password"] {
      max-width: 400px;
      width: 100%;
      height: 48px;
      line-height: 48px;
      background: $scolor2;
      border-radius: 6px;
      margin: 8px 0;

      &::placeholder {
        color: #525c6e;
      }

      &:disabled {
        background-color: #43506f;
        color: #858fa1;
      }
    }

    .email-edit {
      .input-group-text {
        position: absolute;
        right: 0;
        background: unset;
        border: unset;
        padding: 0;
        top: 0;
        bottom: 0;

        button {
          background: unset;
          color: white !important;
          border: unset;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.location-restricted-popup {
  .restrict-text {
    margin-top: 20px;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #405071;
    background-color: #1e2637;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: #fbfdfe;
  }

  .divider {
    border-top: 1px solid #405071;
    height: 0px;
  }

  .support-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .tip-text {
      margin: 0;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 18.75px;
      color: #838fa3;
    }

    .support-list {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .support {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .content {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .title {
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 16.41px;
            color: #ffffff;

            > img {
              width: 24px;
              height: 24px;
            }
          }
          .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 16.41px;
            color: #838fa3;
            text-align: left;
          }
        }

        .button,
        .href {
          padding: 14px 8px;
          border-radius: 6px;
          background-color: #405071;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          line-height: 18px;
          border: none;
          max-width: 140px;
          width: 100%;
          text-decoration: none;
        }
      }
    }
  }

  > .login-message {
    margin: 20px 0 0 0;
  }
}

@include media-breakpoint-up(sm) {
  .login-modal,
  .joinUs-view-container {
    max-width: 440px;
    width: 100%;
  }

  .social-logins {
    display: flex;
  }

  .social-logins {
    width: 100%;
  }

  .social-link {
    margin: 4px;
  }
}

.login-modal-content,
.joinUs-view-form {
  .btn-secondary {
    color: $scolor1 !important;
  }

  .login-title {
    &:first-child {
      margin: 20px 0;
    }

    &.spaced {
      margin: 20px auto 12px;
    }
  }

  /*.social-link,
  .get-scatter-button {
    margin: 0 auto 25px;
  }*/

  .login-button {
    margin: 4px;
    height: 48px;
    display: inline-block;
  }

  .login-advantages {
    border-radius: 6px;
    overflow: hidden;

    img {
      width: 100%;
      height: 112px;
      object-fit: cover;
      object-position: left;
    }
  }

  .get-scatter-button {
    background-color: #61acc9;

    &:hover {
      background: lighten(#61acc9, 5);
    }

    .scatter-btn-logo {
      height: 2rem;
      position: absolute;
      left: 10px;
      border-radius: 4px;
    }
  }
}

.login-promo {
  background: #405071;
  padding: 20px;
  margin-bottom: 16px;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 6px;
    color: #fff;

    span {
      text-transform: uppercase;
      margin: 0 8px;
    }
  }

  a {
    color: #d8dadf;
    text-decoration: underline;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

.login-buttons {
  display: flex;
  width: 100%;
  margin: 0 auto 12px;
  align-items: center;
  justify-content: center;
  max-width: 350px;

  @media screen and (max-width: 380px) {
    flex-wrap: wrap;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__wallets {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 0 0 7px;

    @media screen and (max-width: 380px) {
      justify-content: center;
      padding: 0;
      width: 100%;
      flex-wrap: wrap;
      a.login-button {
        margin: 4px !important;
      }
      .mobile-links {
        a {
          margin: 4px !important;
        }
      }
    }
  }
}

.login-title {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #838fa3;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #405071;
  }

  &::after {
    margin-left: 8px;
  }

  &::before {
    margin-right: 8px;
  }
}

.login-back-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 1rem;

  svg {
    font-size: 16px;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    > .login-back {
      background: $scolor2;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;

      svg {
        font-size: 16px;
      }
    }
  }
}
