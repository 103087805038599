// @import "~bootstrap/scss/bootstrap.scss";

@import "./variables";
@import "./bootstrap_cust.scss";

body {
  background: $bodyback;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.page-loading {
  background: none;
  text-align: center;
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: center;
}

.nav-pills .nav-link,
.nav-tabs .nav-link,
.accordion > .card-header {
  height: 38px;
  background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
  line-height: 38px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  .card-header-title {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .live-indicator {
    margin: 0 0 0 8px !important;
  }

  span {
    margin: 0 0.5rem 0 0;

    &:nth-child(2) {
      .badge {
        margin-left: 0.5rem;
        background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      }

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
    }
  }

  @include hover {
    background: $sel-hover-bg;
  }
}

.accordion.accordion-faq > .card-header {
  min-height: 38px;
  height: auto;
  white-space: break-spaces;
  line-height: 28px;
  padding: 4px 12px;
  align-items: flex-start;
}

.accordion.open > .card-header {
  border-radius: 4px 4px 0 0;
}

.faq .accordion > .card-body {
  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.nav-pills {
  border: none;
  user-select: none;

  .nav-item {
    .nav-link {
      border-radius: 4px;
      border: none;
      padding: 0 0.5rem;
      line-height: 38px;
    }

    &.dropdown {
      > div {
        border-radius: 4px;
      }
    }

    &.d-sm-block + .nav-item.d-sm-block {
      margin-left: 2px;
    }
  }
}

.accordion.card {
  margin-bottom: 4px;
  border: 0;
  background: none;

  .card-header {
    border-bottom: 0;
  }
}

.navbar-dark.bg-dark {
  background-color: transparent !important;
}

.modal-header {
  color: $modal-header-color;
  background: $modal-header-bg;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-1 {
  flex: 1;
}

.main-container {
  padding-top: map-get($spacers, 2);
}

.trust-img {
  width: 100%;
  height: auto;
}

.carousel-caption {
  top: 50px;
  bottom: auto;
  text-shadow: 0 0 0.5em #000, 0 0 1em #000, 0 0 2em #000;
}

.trust-important {
  color: $sportbet;
}

.sport-selection {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 0 8px;
  border: none;
  justify-content: space-between;

  .nav-item {
    width: 80px;
    height: 80px;
    max-width: 80px;
    user-select: none;
    flex-grow: 1;
    border-radius: 4px;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
    }

    & + .nav-item {
      margin-left: 4px;
    }
  }

  .nav-link {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 5px 3px;
    background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
    height: 80px;
    width: 80px;
    border: none;
    position: relative;
    border-radius: 4px;

    &.active {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    }

    &:hover {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    }

    .events-count {
      position: absolute;
      right: 4px;
      top: 4px;
      font-weight: 400;
      font-size: 0.75rem;
    }
  }

  .sport-selection-label {
    font-size: 0.9rem;
    font-weight: 500;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
    color: #c4c4c4;

    span {
      margin: 0;
    }
  }

  .sport-selection-count {
    position: absolute;
    right: 2px;
    top: 2px;
    background: red;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
  }

  .sport-selection-highlight {
    .nav-link {
      background: yellow !important;
    }
  }

  &.sport-selection-homepage {
    justify-content: flex-start;

    .nav-item {
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      overflow: hidden;

      .nav-link {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
        background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;

        .sport-icon {
          transform: scale(0.85);
          position: absolute;
          top: -1px;
          left: -3px;
        }

        .sport-selection-label {
          display: none;
        }
      }

      &.active {
        background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
        width: auto;
        max-width: none;

        .nav-link {
          background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
          width: auto;
          max-width: none;

          .sport-selection-label {
            position: relative;
            display: inline-block;
            flex-grow: initial;
            white-space: nowrap;
            color: #d8dadf;
            padding-left: 30px;
          }
        }
      }
    }
  }
}

.sport-filter {
  max-width: 20rem;
  position: relative;
  background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
  border-radius: 4px;

  input,
  input:focus {
    border: none;
    padding: 6px 12px 6px 36px;
    border-radius: 4px 0 0 4px;
    z-index: 1;
    background: transparent;
    height: 38px;
  }

  button,
  button:focus {
    border: none;
    border-radius: 0 4px 4px 0;
    z-index: auto !important;
    background: transparent;
    width: 40px;

    &:hover {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    }
  }

  .input-group-prepend {
    background: none;
    border: none;
    position: absolute;
    z-index: 4;
    top: 12px;
    left: 12px;

    .input-group-text {
      background: none;
      border: none;
      padding: 0;
    }
  }
}

.accordion {
  > .card-header {
    cursor: pointer;
    color: $sel-active;

    .badge {
      border-radius: 30px;
    }

    &.group-top {
      height: 38px;
      background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
      line-height: 38px;
      padding-top: 0;
      padding-bottom: 0;
      color: #68e690;

      @include hover {
        background: $sel-hover-bg;
      }
    }
  }

  > .card-body {
    padding: 0;
  }

  &.open > .card-header:not(.inverted-count) {
    .badge {
      display: none;
    }
  }
}

.card {
  border: none;

  .card-body {
    background: $body-bg-v2;
  }
}

.league-name {
  color: $league-color;
  background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
  border-radius: 0px 0px 4px 4px;
  padding: 0 $league-spacer-x;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .badge {
    border-radius: 30px;
    margin: 0 0 0 4px;
    height: 18px;
    padding: 0 8px;
    color: #fbfdfe;
    font-size: 12px;
    line-height: 18px;
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
  }

  .nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bet-rejected {
  text-decoration: line-through;
}

.guide {
  font-size: 1.5rem;
}

.navbar-brand {
  padding-left: 0;
  margin-right: 0;
}

.notClickable {
  pointer-events: none;
}

.breadcrumb {
  margin-top: map-get($spacers, 2);

  > .card-header {
    background: $sel-bg;
  }

  .breadcrumb-separator {
    color: darken($link-color, 10);
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
  }

  &.card {
    background-color: $body-bg-v2;
  }

  a {
    display: inline-block;
    padding: 0;
  }
}

.date-header {
  color: $header-color;
  background: $header-bg;
  padding: map-get($spacers, 1) map-get($spacers, 2);
  flex: 1;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@include media-breakpoint-down(md) {
  .navbar {
    > .container {
      padding: 0;
    }
  }
}

.border,
.border-top,
.border-left,
.border-right,
.border-bottom {
  border-color: $sel-active-bg !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar,
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $scolor2;
  border-radius: 4px;
}

.dropdown-divider {
  border-color: darken($sel-active-bg, 10);
}

.alert {
  background: transparent;
  text-align: center;

  &.alert-info {
    border: none;
    color: lighten($sel-active-bg, 10);
    font-style: italic;
  }

  &.alert-success {
    color: darken($accent-color, 10);
  }

  &.alert-danger {
    background: none;
    border-color: lighten($danger, 10);
    margin: 1rem 0;
    color: lighten($danger, 10);
  }

  &.alert-warning {
    color: $warning;
  }

  &.alert-dark {
    color: lighten($sel-active-bg, 20);
    border-color: $sel-active-bg;
  }
}

.popover {
  animation: none;
  transition: none;
  border-radius: 4px;
  border: 0;
  background: $sel-active-bg;

  .popover-body {
    padding: 0;
    border: 0;
  }

  .popover-list-options {
    margin-top: 15px;
    color: white;
    list-style: none;
    padding: 0;
  }

  .arrow {
    &::after {
      border-bottom-color: $sel-active-bg !important;
    }
  }

  .btn {
    border: none;
  }

  .btn-secondary {
    background-color: $sel-bg;
  }
}

.event-type-picker-container {
  border-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4px 0;

  > .event-type-picker + .event-type-picker {
    cursor: pointer;
    margin-left: 4px;
  }

  .event-type-picker {
    background: #010816;
    cursor: pointer;
    border-radius: 4px;
    user-select: none;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .live-icon {
      height: 0.7rem;
    }

    &.active {
      background-color: $sel-bg !important;
      border-top: 0;
      color: $white;
    }
  }
}

.live-icon {
  animation: live-icon-flashing 4s infinite;
}

@keyframes live-icon-flashing {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.data-block {
  margin-bottom: 1rem;

  .data-header-text {
    margin-bottom: 0.3rem;
  }

  .data-text {
    font-weight: 400;
    margin-bottom: 0.2rem;
    font-size: 0.8rem;
  }
}

.advanced-settings {
  .card-body {
    background-color: $sel-bg;
  }
}

.bets-nav {
  margin: 0 0 4px 0;

  .nav-item {
    overflow: hidden;
    flex-grow: 1;

    & + .nav-item {
      margin-left: 4px;
    }

    a {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.live-score-disclaimer {
  cursor: pointer;
  color: lighten($sportbet, 10);
}

.lynx-gradient-button {
  background-image: linear-gradient(
    to right,
    #dc2430 0%,
    #ab3765 25%,
    #7d4997 50%,
    #ab3765 75%,
    #dc2430 100%
  );
  transition: background-position 0.5s ease 0.2s;
  background-size: 200% auto;
  background-position: left;

  & span {
    font-weight: bold;
    letter-spacing: 1px;
  }

  & .lynx-logo {
    position: absolute;
    left: 10px;
  }

  &:hover {
    background-position: right center;
    color: white;
  }

  &:focus {
    outline: 0;
    color: white;
  }

  &:active {
    color: white;
    border-color: transparent;
  }
}

.wallet-login-button {
  width: 276px;
  height: 50px;
  margin: 25px auto;
  display: flex;
  justify-content: center;
  border: 0;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  border-radius: 4px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  text-decoration: none !important;

  &:focus {
    outline: 0;
    color: white;
  }

  &:hover {
    color: white;
  }

  &:active {
    color: white;
    border-color: transparent;
  }
}

@include media-breakpoint-up(sm) {
  .login-modal,
  .joinUs-view-container {
    max-width: 380px;
  }
}

.login-modal-content {
  .login-title {
    &:first-child {
      margin: 0 0 15px;
    }

    &.spaced {
      margin: 5px auto 15px;
    }
  }

  /*.social-link,
  .get-scatter-button {
    margin: 0 auto 25px;
  }

  .social-link {
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 350px;
      margin: 0 auto 12px !important;
    }
  }*/

  .login-button {
    margin: 0 8px 0 0 !important;
    height: 48px;
    width: 48px;
    display: inline-block;
  }

  .get-scatter-button {
    background-color: #61acc9;

    &:hover {
      background: lighten(#61acc9, 5);
    }

    .scatter-btn-logo {
      height: 2rem;
      position: absolute;
      left: 10px;
      border-radius: 4px;
    }
  }
}

.card {
  background-color: $body-bg-v2;
}

ul.pagination {
  border-radius: 4px;
  overflow: hidden;
}

.page-link {
  background: $scolor4;
  border: none;
  box-shadow: none !important;
  line-height: 1;
  cursor: pointer;

  &.active {
    font-weight: 600;
    background: lighten($scolor4, 10);
  }

  &:hover {
    background: lighten($scolor4, 10);
  }
}

.dropdown {
  // suffix 'bt' is for DropdownToggle component override
  .bt-dropdown-toggle {
    position: relative;
    min-height: $btn-height;

    &:after {
      display: none !important;
    }

    .caret-icon {
      font-size: 0.85rem;
      vertical-align: middle;
      line-height: 1;

      > svg {
        margin-inline-start: 0.5em;
      }
    }

    &.caret-stacked {
      display: flex;
      align-items: center;

      .caret-icon {
        // push to the end
        margin-inline-start: auto;
      }
    }
  }

  .dropdown-toggle {
    position: relative;
    min-height: $btn-height;

    &.caret-stacked {
      &:after {
        position: absolute;
        right: 0.6rem; // parent padding right
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  .dropdown-menu {
    &.full-width {
      min-width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1190px;
  }
}

.site-logo.header-logo {
  background-image: url("/images/logo.svg");
  width: 130px;

  @media screen and (max-width: 800px) {
    width: 40px;
    background-image: url("/images/logo-short.svg");
  }
}

.footer-socials {
  display: flex;

  a {
    background: $scolor2;
    margin: 0 8px 0 0;
    width: 36px;
    height: 36px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &:last-child {
      margin: 0;
    }
  }
}

.hide-mobile {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.show-mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }

  p {
    width: 100%;
    max-width: 100% !important;
    text-align: center;
  }
}

.footer-row {
  margin: 0 !important;

  .col-12:first-child {
    @include media-breakpoint-up(md) {
      padding-left: 0;
    }
  }

  &.gap-y-6 {
    gap: 24px 0;
  }
}

.beta-badge {
  font-size: 10px;
  background: #dc3545;
  display: block;
  position: absolute;
  right: -32px;
  color: #fff;
  line-height: 11px;
  padding: 1px 2px;
  top: 10px;
  user-select: none;
}

.app-context-buttons {
  position: fixed;
  bottom: 20px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #73e698;
    cursor: pointer;
    color: #000;
    border-radius: 50%;
    margin: 0 8px 8px 0;

    &:hover {
      color: #000;
    }
  }
}

body.betslip-shown {
  .app-context-buttons {
    @media screen and (max-width: 800px) {
      flex-wrap: wrap;
      flex-direction: column;
    }
    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
      flex-direction: column;
      bottom: 80px;
      right: 0;
    }
  }
}

.html-content {
  color: #d8dadf;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 32px;

  h1,
  h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: #fbfdfe;
    margin: 0 0 24px;
  }

  .responsive-table {
    overflow-x: scroll;
    margin: 0 0 18px;
  }

  table {
    border-collapse: separate;
    margin: 0 0 5px;

    td {
      background: #1e2637;
      border: 1px solid transparent;
      padding: 8px 40px;
      text-align: center;
      color: #d8dadf;
      font-size: 16px;
      line-height: 16px;
    }
  }

  a {
    color: #73e698;
    text-decoration: underline;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

.faq-content {
  color: #d8dadf;
  font-size: 16px;
  line-height: 22px;

  h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: #fbfdfe;
    margin: 0 0 12px;
  }
  a {
    color: $primary2;
    text-decoration: underline;
  }
}

label.radio {
  user-select: none;
  color: #a1a6b0;
  position: relative;
  cursor: pointer;

  input[type="radio"] {
    opacity: 0;
  }

  .check:after {
    content: "";
    display: none;
    width: 8px;
    height: 8px;
    background: #838fa3;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: -17px;
  }

  .check:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid #838fa3;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: -21px;
  }

  input[type="radio"]:checked + .check:before {
    display: block;
    border-color: #d8dadf;
  }

  input[type="radio"]:checked + .check:after {
    display: block;
    background: #d8dadf;
  }

  input[type="radio"]:checked ~ .caption {
    color: #d8dadf;
  }

  &:hover .check:after {
    display: block;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input {
  input {
    height: 48px !important;
  }

  &-sum {
    input {
      border-radius: 6px;
      margin: 8px 0;
      padding-left: 40px !important;
    }
  }
}

.input.input-icon {
  position: relative;

  > img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 13px;
  }

  input {
    padding-left: 40px !important;
  }
}

.dropdown-menu .dropdown-item.active.hash-route {
  &:hover {
    background: #526792;
  }
}

.side-menu-container .nav-link.active.popup-link {
  color: inherit;
}

.widget-counters {
  display: flex;
}

.widget-link {
  display: flex;
  justify-content: center;
  margin: 0 6px 0 0;

  &:last-child {
    margin: 0 2px 0 0;
  }
}

.widget-counter {
  font-size: 10px;
  background: #73e698;
  color: #000;
  position: absolute;
  height: 12px;
  line-height: 14px;
  width: auto;
  top: 4px;
  padding: 2px 2px;
  left: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.homepage-nav {
  .homepage-sports {
    max-width: 100%;

    & ::-webkit-scrollbar {
      display: none;
    }
  }

  .sport-filter {
    max-width: 30%;
    height: 42px;
  }
}

.homepage-sports {
  display: flex;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 0 4px;
  margin: 4px 0 0 0;

  &__item {
    min-width: 50px;
    max-width: 50px;
    width: 50px;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    background: linear-gradient(90.07deg, #212a3d 0%, #1e2637 100%);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 4px 0 0;
    cursor: pointer;
    user-select: none;

    img {
      width: 24px;
      height: 24px;
    }

    &-title {
      display: none;
    }

    &-icon {
      width: 50px;
      position: absolute;
      left: 1px;
      top: 2px;
      transform: scale(0.96);
      padding: 0.5rem 0.5rem;
    }

    .homepage-sports__item-count {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 9px;
      color: #838fa3;
    }

    &:hover {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    }

    &:not(:first-child).active {
      min-width: auto;
    }

    &.active {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
      padding: 0 12px;
      max-width: none;
      width: auto;

      .homepage-sports__item-title {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: #fbfdfe;
        white-space: nowrap;
        margin: 0 0 0 34px;
      }
    }
  }
}

.copy-button {
  position: relative;
}

.input-copy-container {
  position: relative;

  .copy-button {
    position: absolute;
    top: 12px;
    right: 6px;
    color: #838fa3;
    cursor: pointer;

    &:hover {
      color: #d8dadf;
    }
  }
}

.input-copy {
  background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
  border-radius: 6px;
  font-size: 16px;
  line-height: 48px;
  color: #838fa3;
  height: 48px !important;
  padding: 0 40px 0 16px;
  margin: 0 !important;
  border: 1px solid #3f4f70;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  svg {
    margin: 0;
  }
}

.live-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #dc3545;
  display: inline-block;
  margin: 0 4px 2px 4px !important;
}

.live-indicator-active {
  margin: 2px 4px 2px 6px !important;
}

.events-grid-show-all {
  display: block;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 12px;
  background: linear-gradient(
    152.08deg,
    darken(#354361, 5%) 0%,
    darken(#303d58, 5%) 5.47%
  );
  border-radius: 0 4px 0 0;
}

.dropdown-menu-filter {
  background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);

  .dropdown-item {
    padding: 8px 12px;
    background-color: transparent !important;
    min-width: 100px;
    display: flex;

    > span {
      line-height: 28px;
      margin: 0 0 0 8px;
    }

    &:hover,
    &.active {
      background-color: #40598e !important;
    }
  }
}

.sport-date-filter {
  .dropdown-menu {
    min-width: 150px;
  }

  &__count {
    position: absolute;
    right: 12px;
    color: #a1a6b0;
    font-size: 12px;
  }
}

.score-header {
  .card-header {
    span {
      overflow: visible !important;
      white-space: normal;
    }

    .disclaimer-tooltip-text {
      line-height: 16px;
    }
  }
}

.overflow-visible {
  overflow: visible !important;
}

a {
  [disabled],
  &.disabled {
    pointer-events: none;
    opacity: 0.65;
  }
}

.link-underline {
  color: rgb(0, 157, 255);
  text-decoration: underline;
}

.text-gray-2 {
  color: $gray2;
}

.text-white-2 {
  color: $white2;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@import "./bet_button";
@import "./events_list.scss";
@import "./event_details.scss";
@import "./sport-icons.scss";
@import "./footer.scss";
@import "./header.scss";
@import "./refill.scss";
@import "./modals.scss";
@import "./forms.scss";
@import "./buttons.scss";
@import "./menus.scss";
@import "./typography.scss";
@import "./side_menu.scss";
@import "./currency_picker.scss";
@import "./settings.scss";
@import "./tables.scss";
@import "./leaderboard";
@import "./endpoints";
@import "./bet-modal";
@import "./login";
@import "./wallet";
@import "./live-stream";
@import "./event_status";
@import "./tutorial";
@import "./featured-event";
@import "./promo";
@import "./paging";
@import "./casino";
@import "./system-down-popup";
@import "./login-popup";
@import "./text-expander";
@import "./footer-expander";
@import "./bet-slip";
@import "./settings-popup";
@import "./challenge";
@import "./radio-tabs";
@import "./notifications";
@import "./breadcrumbs";
@import "./history";
@import "./bonus";
@import "./zchat";
@import "./coins-widget";
@import "./sports-navigation";
@import "./slider";
@import "./bonuses";
@import "./rules";
@import "./about-us-page";
@import "./self-exclude";
@import "./animations";
