.leaderboard-nav-link {
  height: 1.6rem;
}

.leaderboard-modal {
  .modal-body {
    min-height: 38rem;

    @include media-breakpoint-down(xs) {
      padding: 0.5rem;
    }
  }

  @include media-breakpoint-up(sm) {
    min-width: 560px;
  }
}

.lb-countdown-time {
  font-family: "Ubuntu Mono, Monaco";
}

.lb-countdown-section {
  background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
  color: lighten($sel-active-bg, 40);
  border-radius: 4px;
  padding: 0.5rem;
  overflow: hidden;
  line-height: 1.1;
  flex: 1 1 0px;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  .lb-countdown-measure {
    font-size: 0.7rem;
  }
}

.leaderboard-table {
  min-height: 22rem;
}

.leaderboard-row-user.ellipsis-row {
  background: transparent;
  cursor: auto;

  div {
    grid-column-end: span 8;
  }
}

.leaderboard-row {
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: 1.5rem 9rem 1fr 2rem 1fr 2.5rem 1.5rem;
  grid-template-rows: auto;
  padding: 0.3rem;

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1.5rem 7rem 1fr 2rem 1.5rem;
    padding: 0.3rem 0;
  }
}

.leaderboard-header {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.7rem;
  color: lighten($sel-active-bg, 20);
}

.leaderboard-row-user {
  border-radius: 4px;
  background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 300;
  cursor: pointer;
  color: $font1;

  .lb-volume-breakdown-header,
  .lb-payout-breakdown-header {
    margin-top: 10px;
  }

  &.winner {
    .lb-username {
      color: $sportbet;
      grid-row-end: span 2;
    }
  }

  .lb-username {
    overflow-wrap: break-word;
    padding: 0 0.2rem;
  }

  &.is-current-user {
    background: linear-gradient(180deg, #48629a 0%, #40598e 100%);
    color: $font3;

    .lb-username {
      font-weight: 600;
    }

    .lb-currency {
      color: $font3;
    }

    .leaderboard-header {
      color: #d8dadf;
    }
  }

  .svg-inline--fa {
    justify-self: center;
    grid-row: 1;
    grid-column: 7;

    @include media-breakpoint-down(xs) {
      grid-column: 5;
    }
  }

  .lb-volume-breakdown-header {
    grid-column: 3;
    grid-column-end: span 2;
  }

  .lb-empty-breakdown-message {
    grid-column: 1;
    grid-column-end: span 7;
  }

  .lb-payout-breakdown-header {
    grid-column: 5;
    grid-column-end: span 2;

    @include media-breakpoint-down(xs) {
      grid-column: 3;
      grid-column-end: span 2;
    }
  }

  &.user-row-opened {
    grid-template-rows: auto auto;
  }

  .volume-details {
    grid-area: 2/3;
  }

  .payout-details {
    grid-area: 2/4;
  }

  .lb-amount {
    text-align: right;
    margin-right: 0.3rem;
  }

  .lb-currency {
    text-align: left;
    color: $font2; // lighten($sel-active-bg, 30);
  }

  .lb-volume-amount {
    grid-column: 3;
  }

  .lb-volume-currency {
    grid-column: 4;
  }

  .lb-payout-amount {
    grid-column: 5;

    @include media-breakpoint-down(xs) {
      grid-column: 3;
    }
  }

  .lb-payout-currency {
    grid-column: 6;

    @include media-breakpoint-down(xs) {
      grid-column: 4;
    }
  }
}

.leaderboard-metadata {
  color: lighten($sel-active-bg, 20);
}
