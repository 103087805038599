.radio-tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 8px;

  li {
    width: calc(50% - 4px);

    &:first-child {
      margin: 0 4px 0 0 !important;
    }

    &:last-child {
      margin: 0 0 0 4px;
    }
  }

  label.radio {
    width: 100%;
    background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    border-radius: 4px;
    height: 48px;
    margin: 0;
    display: flex;
    align-items: center;

    &.active {
      background: linear-gradient(180deg, #48629a 0%, #40598e 100%);
    }

    .check {
      position: relative;
      width: 16px;
      height: 16px;
      margin: 0 0 0 12px;

      &:before {
        top: unset;
        left: unset;
      }

      &:after {
        top: 4px;
        left: 4px;
      }
    }

    .caption {
      margin: 0 0 0 12px;
    }
  }
}
