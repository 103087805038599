button,
.btn,
.btn:hover,
.btn:active,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
  transition: color, background, background-color, background-position 0.2s ease;
  color: #000;

  .button-icon-start {
    margin-right: 0.5rem;
  }

  .button-icon-end {
    margin-left: 0.5rem;
    margin-bottom: -1px;
  }
}

button.btn-success.btn-black-text {
  color: #000 !important;
}

.wallet-btn-primary {
  background-color: #48629a;
  background-image: linear-gradient(180deg, #48629a 0%, #40598e 100%);
  min-height: 48px;
  min-width: calc(50% - 8px);
  margin: 0 0 8px 0;
  margin-left: 8px;
  font-size: 1rem;
  line-height: 2rem;
  color: #fff !important;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    background-image: linear-gradient(180deg, #4d69a6 0%, #40598e 100%);
    color: #fbfdfe;
  }
}

.close {
  text-shadow: none;
}

.radio-button-group {
  display: flex;
  align-items: center;

  .btn {
    line-height: 0.7;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

@media screen and (min-width: 801px) {
  .user-menu-container {
    display: block !important;
  }
}

.dropdown.user-menu-toggler {
  .dropdown-toggle.btn-secondary {
    background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #58d673 100%);
    border-radius: 5px;
    font-weight: 600;
    font-size: 0.9rem;
    box-shadow: none;
    border: 0 !important;
    outline: none;
    color: $body-bg !important;
    text-align: left;

    > .user-name {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.user-name-desktop {
        max-width: 110px;

        span {
          max-width: 110px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &:hover {
      background: linear-gradient(
        180deg,
        #a4f4b7 0%,
        #6ff78c 0.01%,
        darken(#58d673, 10) 100%
      );
    }

    &:active {
      background: linear-gradient(
        180deg,
        #a4f4b7 0%,
        #6ff78c 0.01%,
        darken(#58d673, 20) 100%
      );
    }
  }

  &.show .dropdown-toggle {
    background: linear-gradient(
      180deg,
      #a4f4b7 0%,
      #6ff78c 0.01%,
      darken(#58d673, 20) 100%
    );
  }

  .dropdown-menu {
    border-radius: $border-radius-md;
  }

  .login-status-container & {
    max-width: 150px;
  }
}

.btn-secondary {
  color: $btnfont2 !important;
}

.btn-submit {
  background: linear-gradient(180deg, #48629a 0%, #40598e 100%);
  border-radius: 4px;
  height: 48px;
  color: #d8dadf;
  font-size: 16px;
  line-height: 19px;
  border: none;
  padding: 0 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover,
  &:focus,
  &:active {
    background-color: darken(#303d58, 1%) !important;
    background-image: none;
  }
  &:disabled,
  &:disabled:active,
  &:disabled:focus,
  &:disabled:focus-within {
    background-color: darken(#303d58, 3%) !important;
    background-image: none;
    color: #acaeb2;
  }
}

a.btn-submit {
  outline: none;
  border: none;
  box-shadow: none;
  user-select: none;
  line-height: 48px;
}

.btn-main {
  color: #000000 !important;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #58d673 100%);
  border-radius: 4px;
  height: 48px;
  padding: 0 24px;
  margin: 0 8px 0 0;

  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #58d673 100%);
  }
}

.btn-success {
  background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #58d673 100%);

  &:hover,
  &:active {
    background: linear-gradient(
      180deg,
      #a4f4b7 0%,
      #6ff78c 0.01%,
      darken(#58d673, 5%) 100%
    );
  }
}

.btn.btn-white:hover {
  color: #000;
}

.btn-tab-1 {
  min-height: 48px; // btn-lg
  // btn styles
  font-size: 1rem; // 16px
  line-height: 2rem;
  color: $white2 !important;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;

  background-color: #354361;
  background-image: linear-gradient(180deg, #354361 0%, #303d58 100%);

  &:hover,
  &:focus,
  &:active {
    background-color: darken(#303d58, 1%);
    background-image: none;
  }

  &:active,
  &.active {
    background-color: #48629a !important;
    background-image: linear-gradient(180deg, #48629a 0%, #40598e 100%) !important;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#40598e, 1%) !important;
      background-image: none !important;
    }
  }
}

.btn-type-1 {
  min-height: 48px; // btn-lg
  // btn styles
  font-size: 1rem; // 16px
  line-height: 2rem;
  color: $white2 !important;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;

  &.btn-primary {
    background-color: #48629a;
    background-image: linear-gradient(180deg, #48629a 0%, #40598e 100%);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):active {
      background-color: darken(#40598e, 1%);
      background-image: none;
    }
  }

  &.btn-secondary {
    background-color: #354361;
    background-image: linear-gradient(180deg, #354361 0%, #303d58 100%);

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#303d58, 1%);
      background-image: none;
    }
  }
}

$btns-grid-gap: 4px;

.btns-grid.row {
  //display: flex;
  //flex-wrap: wrap;
  //width: auto;
  margin-left: -$btns-grid-gap;
  margin-right: -$btns-grid-gap;
  margin-bottom: -$btns-grid-gap;

  > .col,
  > [class^="col-"] {
    padding-left: $btns-grid-gap;
    padding-right: $btns-grid-gap;

    > [class^="btn-"] {
      margin-bottom: $btns-grid-gap;
    }
  }
}
