.footer-expand {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0 50px;
  height: 100px;
  overflow: hidden;

  .footer-column {
    display: flex;
    justify-content: flex-end;
  }

  &__button-wrapper {
    background: linear-gradient(180deg, rgba(21, 27, 40, 0) 0%, #151b28 75%);
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
  }

  &__button {
    width: 36px;
    height: 36px;
    background: #1e2637;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: #a1a6b0;
    }

    &:hover {
      background: #525c6e;

      svg {
        color: #fff;
      }
    }
  }

  &.expanded {
    height: auto;
    overflow: auto;

    .footer-expand__button-wrapper {
      background: none;
      height: 36px;
      position: relative;
    }
  }
}
