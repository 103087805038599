.bonus-modal {
  max-width: 600px;

  .modal-body {
    min-height: 500px;
  }
}

.bonus-amounts {
  columns: 2;

  @media screen and (max-width: 768px) {
    columns: 1;
  }
}

.bonus-amount {
  margin: 0 0 4px;

  &__label {
    font-size: 14px;
    margin: 0 0 8px;
  }

  &__value {
    background: linear-gradient(152.08deg, #354361 0%, #303d58 100.47%);
    color: #e5e5e5;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 0 8px;

    &.dark {
      background: linear-gradient(180deg, #425375 0%, #3f4f70 100%);
    }

    @media screen and (max-width: 550px) {
      justify-content: flex-start;
      padding: 0 12px;
    }

    > div {
      overflow: hidden;

      > span:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.bonus-claim-button {
  margin: 0;
  min-width: 100px;
}

.bonus {
  margin: 0 0 24px 0;

  &-header {
    display: flex;
    align-items: center;
    margin: 0 0 8px;
    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0 0 0;
    width: 100%;

    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
  }

  &-caption {
    margin: 0 0 0 12px;
    color: #a1a6b0;
    font-size: 14px;

    @media screen and (max-width: 500px) {
      width: 100%;
      margin: 12px 0 0;
    }
  }
}

.bonus-status {
  background: #838fa3;
  color: #354361;
  border-radius: 5px;
  padding: 0 4px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;

  &.active {
    background: linear-gradient(180deg, #a4f4b7 0%, #6ff78c 0.01%, #58d673 100%);
    color: #000917;
  }
}

.bonus-title {
  margin: 0 0 0 8px;
  text-transform: uppercase;
  font-size: 14px;
}

.bonus-total {
  display: flex;
  color: #a1a6b0;
  width: 100%;
  font-size: 14px;
  margin: 0 0 8px 0;

  &__amount {
    margin: 0 0 0 8px;
    color: #fff;
  }
}

.bonus-counter {
  font-size: 14px;
  color: #a1a6b0;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 8px 0 0 0;
  }

  &__value {
    margin: 0 0 0 8px;
    color: #fff;
  }
}

.bonus-cols {
  display: flex;
  justify-content: space-between;
  width: 100%;

  > .bonus-col:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.bonus-progress-container {
  width: 110px;
  height: 110px;
  position: relative;
}

.bonus-progress {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;

  .CircularProgressbar-path {
    stroke: #58d673;
    stroke-linecap: round;
  }

  .CircularProgressbar-trail {
    stroke: #526792;
  }

  .CircularProgressbar-text {
    fill: #fff;
  }

  .CircularProgressbar-background {
    fill: green;
  }
}

.bonus-progress-value {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  user-select: none;
  z-index: 2;

  span {
    color: #fff;
    font-size: 16px;
  }
}

.wallet-bonus-deposit-message {
  display: flex;
  background: #405071;
  border: 2px solid #526792;
  border-radius: 5px;
  padding: 16px 20px;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 8px 0;

  img {
    width: 36px;
    height: 36px;
    margin: 0 12px 0 0;
  }

  div {
    font-size: 16px;
    line-height: 22px;
    color: #fbfdfe;
  }

  &.deposit-message {
    div {
      font-size: 16px;
    }
  }
}

.wallet-bonus-wager-message {
  display: flex;
  background: #405071;
  border: 2px solid #526792;
  border-radius: 5px;
  padding: 16px 20px;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 8px 0;

  &-balance {
    display: flex;

    &-title {
      color: #838fa3;
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 8px 8px 0;

      img {
        width: 18px;
        height: 18px;
        margin: 0 4px 0 0;
      }
    }

    &-value {
      font-size: 14px;
      line-height: 23px;
      color: #fbfdfe;
      margin: 0 0 8px 0;
    }
  }

  a {
    font-size: 16px;
    text-decoration: none;
    color: #5bda77;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  .bonus-progress-container {
    width: 86px;
    height: 86px;
  }
}
